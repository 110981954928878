precision mediump float;


varying vec2 vUv;
uniform float uTime;

void main() {

    vUv= uv;
    vec3 newPos= position;
    newPos.z += sin(floor(uv.x*100.)*10.);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);

}