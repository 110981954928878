import * as THREE from 'three'
import EventDispatcher from '../common/EventDispatcher'
import { Global } from '../common/global';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'


export default class AssetLoader{
    constructor(scene){
        this.scene=scene;
    }
    init(){
        this.events= EventDispatcher.getObj();
        this.baseURL= '';
        this.loadingMgr= new THREE.LoadingManager(
            ()=>{
                this.events.dispatchEvent({ type: "LOADER_EVENTS", message: {
                    "event_type":"load_complete",
                    data:{
        
                    }
                }});
                this.checkLogin()
            },
            ()=>{
            },
            ()=>{
            }
        );

    
        this.textureLoader = new THREE.TextureLoader(this.loadingMgr);
        this.textureLoader.setPath(this.baseURL);

        this.audioLoader = new THREE.AudioLoader(this.loadingMgr);
        this.audioLoader.setPath(this.baseURL);
        
        this.cubeTextureLoader = new THREE.CubeTextureLoader(this.loadingMgr);
        this.cubeTextureLoader.setPath(this.baseURL);

        this.gltfLoader= new GLTFLoader(this.loadingMgr);
        this.gltfLoader.setPath(this.baseURL);
        this.dracoLoader = new DRACOLoader();
        this.dracoLoader.setDecoderPath( '/libs/draco/' );
        this.gltfLoader.setDRACOLoader( this.dracoLoader );
    }
    load(){
        let assetsToLoad= Global.assets;
        Object.keys(assetsToLoad).forEach((typeKey)=>{
            let itemKeys= Object.keys(assetsToLoad[typeKey]);
            itemKeys.forEach((itemKey)=>{
                if(typeKey == 'textures'){
                    this[assetsToLoad[typeKey][itemKey]['key']]= this.textureLoader.load(assetsToLoad[typeKey][itemKey]['path']);
                    Global.assets[typeKey][itemKey]['asset']=this[assetsToLoad[typeKey][itemKey]['key']]
                }
                if(typeKey == 'cubemap'){
                    this[assetsToLoad[typeKey][itemKey]['key']]= this.cubeTextureLoader.load(assetsToLoad[typeKey][itemKey]['path']);
                    Global.assets[typeKey][itemKey]['asset']=this[assetsToLoad[typeKey][itemKey]['key']]
                }
                if(typeKey == 'glbs'){
                    this[assetsToLoad[typeKey][itemKey]['key']]= this.gltfLoader.load(assetsToLoad[typeKey][itemKey]['path'][0],(gltf)=>{
                        Global.assets[typeKey][itemKey]['asset']=gltf;
                    });
                }
               
                /* if(typeKey == "sounds"){
                    let x=this.audioLoader.load(assetsToLoad[typeKey][itemKey]['path'],function(typeKey, itemKey, buffer){
                        Global.assets[typeKey][itemKey]['asset']=buffer;
                        console.log(buffer,'BGSound')
                    }.bind(this, typeKey, itemKey));
                    console.log(x,'xxxx')
                    
                } */
        
            })
            // console.log(itemKeys,'itemKeys')
            // if(typeKey == 'cubemap')
        });
       
        /* this.environmentMapTexture = this.cubeTextureLoader.load([
            '/textures/environmentMaps/0/px.png',
            '/textures/environmentMaps/0/nx.png',
            '/textures/environmentMaps/0/py.png',
            '/textures/environmentMaps/0/ny.png',
            '/textures/environmentMaps/0/pz.png',
            '/textures/environmentMaps/0/nz.png'
        ]);
        console.log(this.environmentMapTexture) */
    }
    checkLogin(){
        if (Global.isTablet) {
            document.querySelector("#desktopContainer").style.display = "block";
            document.querySelector('.loader').style.display = 'none'
        }
        if (Global.isMobile) {
            document.querySelector(".container").style.display = "block";
            document.querySelector(".container").style.left = "0";
            document.querySelector('.loader').style.display = 'none'
            Global.name = localStorage.getItem("name");
            Global.mobile = localStorage.getItem("mobile");
            Global.email = localStorage.getItem("email");

            // Global.bgAudio.play();
            // Global.teamId = location.href.substring(location.href.lastIndexOf('?teamId=') + 1).split("teamId=")[1];
            if (
                Global.mobile == "" ||
                typeof Global.mobile == "undefined" ||
                Global.mobile == null ||
                Global.mobile == 0 ||
                Global.name == "" ||
                typeof Global.name == "undefined" ||
                Global.name == null ||
                Global.name == 0
            ) {

                document.querySelector("#landingPage").style.display = "flex";
            } else {
                // Global.teamId = location.href.substring(location.href.lastIndexOf('?teamId=') + 1).split("teamId=")[1];
                // document.querySelector('#gullyGame').src = './assets/Top-Tile_' + Global.teamId + '.png';
                // document.querySelector('#arExp').src = './assets/' + Global.teamId + '-Left-tile.png';
                // document.querySelector('#reward').src = './assets/' + Global.teamId + '-Right-tile.png';
                // document.querySelector('#teamLogo').src = './assets/partner-logo-' + Global.teamId + '.png';
                // document.querySelector('.welcome').innerHTML = 'Welcome ' + Global.name;
                // document.querySelector('.teamSelectPage').style.display = 'none';
                Global.U_ID = localStorage.getItem("U_ID");
                Global.gameKey = localStorage.getItem("gameKey");
                Global.gameTry = localStorage.getItem("gameTry");
                Global.image_no = localStorage.getItem("image_no");
                const jwt = localStorage.getItem("jwt");
                Global.JWT = jwt;
                Global.serverObj.send(
                    Global.URL_DATA,
                    (data) => {
                        var data = JSON.parse(data);
                        if (data.message == "active") {
                            Global.loginStatus = true;
                            Global.firstName = Global.name.split(" ")[0];
                            document.querySelector(
                                "#userName"
                            ).innerHTML = `hello ${Global.firstName}`;
                            document.querySelector("#homePage").style.display = "flex";
                            Global.recieptStatusUpdate()
                            Global.homePageAnimation(100)

                        } else {
                            localStorage.clear();
                            window.location.href = "https://www.oreoplayfuldunks.com/?utm_source=QR";
                        }
                    },
                    null,
                    {
                        saveType: "logincheck",
                        phone: Global.mobile,
                        uid: Global.U_ID,
                    },
                    "POST",
                    null,
                    false
                );
            }

        } else {
            document.querySelector("#desktopContainer").style.display = "block";
            document.querySelector('.loader').style.display = 'none'

        }   
    }
}