import * as THREE from 'three'

import EventDispatcher from '../common/EventDispatcher';
import {
    Global
} from '../common/global';

import {
    gsap,
    Expo,
    Linear
} from 'gsap';
import {
    shuffle
} from '../common/array-util';
import {
    isMobile,
    isMobileOnly
} from 'mobile-device-detect';

import vertexShader from '../shader/water/vertex.glsl';
import fragmentShader from '../shader/water/fragment.glsl';
import {
    Reflector
} from './Reflector'

export default class GameScene {
    constructor(scene, camera) {
        this.scene = scene;
        this.camera = camera;

        this.maze = null;

        this.lastCheckPt = 1;
        this.mazeData = {
            "1": {
                x: [1],
                z: [0]
            },
            "2": {
                x: [-1],
                z: [1]
            },
            "3": {
                x: [-1],
                z: [-1]
            },
            "4": {
                x: [1],
                z: [1]
            },
            "5": {
                x: [1],
                z: [1, -1]
            },
            "6": {
                x: [0],
                z: [-1]
            },
            "7": {
                x: [-1],
                z: [1, -1]
            },
            "8": {
                x: [0],
                z: [1]
            },
            "9": {
                x: [-1],
                z: [-1]
            },
            "10": {
                x: [1, -1],
                z: [-1]
            },
            "11": {
                x: [1],
                z: [-1]
            },
            "12": {
                x: [1],
                z: [1]
            },
            "13": {
                x: [-1],
                z: [1, -1]
            },
            "14": {
                x: [-1],
                z: [1]
            },
            "15": {
                x: [0],
                z: [-1]
            },
            "16": {
                x: [1],
                z: [-1]
            },
            "17": {
                x: [1],
                z: [1]
            },
            "18": {
                x: [-1],
                z: [1]
            },
            "19": {
                x: [1],
                z: [-1]
            },
            "20": {
                x: [-1],
                z: [0]
            },
            "21": {
                x: [1],
                z: [-1, 1]
            },
            "22": {
                x: [-1],
                z: [-1]
            },
            "23": {
                x: [0],
                z: [1]
            },
            "24": {
                x: [-1],
                z: [0]
            },
            "25": {
                x: [1],
                z: [-1]
            },
            "26": {
                x: [1, -1],
                z: [1]
            },
            "27": {
                x: [1],
                z: [1, -1]
            },
            "28": {
                x: [1],
                z: [1]
            },
            "29": {
                x: [-1],
                z: [1]
            },
            "30": {
                x: [0],
                z: [-1]
            },
            "31": {
                x: [-1, 1],
                z: [-1]
            },
            "32": {
                x: [0],
                z: [1, -1]
            },
            "33": {
                x: [-1],
                z: [0]
            }
        }
    }
    init() {
        this.events = EventDispatcher.getObj();
        this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("PLAYER_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("GAME_EVENTS", this.onEvents.bind(this));



    }
    onEvents(data) {
        switch (data['message']['event_type']) {
            case "create_scene":
                this.createScene();
                break;
            case "move_player":
                this.checkForCheckPt(data['message']['data']);
                break;
            case "update":
                this.update(data['message']['data']);
                break;
            case "update_global_directions":
                this.updateGlobalDirections(data['message']['data'], true);
                break;
            case "hide_maze":
                this.maze && (this.maze.visible = false, this.mazePoints.visible = false);
                break;
            case "show_maze":
                this.maze && (this.maze.visible = true, this.mazePoints.visible = true);
                break;
            case "replay_game":
                this.onReplay();
                break;

        }
    }
    updateGlobalDirections(data, isPartial, direction) {






        if (!isPartial) {

            Global.movePossibleDirns = {
                'x': [...this.mazeData[String(this.lastCheckPt)]['x']],
                'z': [...this.mazeData[String(this.lastCheckPt)]['z']]
            }
        }
        if (Global.lastCheckPt.position.distanceTo(data['position']) >= 0.05) return false;
        if (Global.lastMoveDirection == 'x' && isPartial && Global.lastDirection == 'z' /*  && (Global.movePossibleDirns['z'].length==1 && Global.movePossibleDirns['z'][0] == 0) && direction == 'z' */ ) {

            Global.movePossibleDirns['z'] = [...this.mazeData[String(this.lastCheckPt)]['z']];
        }
        if (Global.lastMoveDirection == 'z' && isPartial && Global.lastDirection == 'x' /* && (Global.movePossibleDirns['x'].length==1 && Global.movePossibleDirns['x'][0] == 0) && direction == 'x' */ ) {

            Global.movePossibleDirns['x'] = [...this.mazeData[String(this.lastCheckPt)]['x']];
        }
        /* Global.movePossibleDirns={
            'x': [...this.mazeData[String(this.lastCheckPt)]['x']],
            'z': [...this.mazeData[String(this.lastCheckPt)]['z']]
        } */
        /* Global.movePossibleDirns={
            'x': [...this.mazeData[String(this.lastCheckPt)]['x']],
            'z': [...this.mazeData[String(this.lastCheckPt)]['z']]
        } */
        /* */
    }
    onReplay(){
        console.log("onReplayonReplay")
        this.lastCheckPt = 1;
        Global.lastCheckPt = this.mazePoints.children[this.lastCheckPt - 1];
        Global.startPos = this.mazePoints.children[0/* this.mazePoints.children.length-3 */].position;

    }
    checkForCheckPt(data) {

        this.mazePoints.children.forEach((pt) => {
            if (pt.position.distanceTo(data['position']) <= .065 && (parseInt(pt.name.split("Plane")[1]) != this.lastCheckPt || (this.mazeData[String(this.lastCheckPt)] && this.mazeData[String(this.lastCheckPt)][data['direction']] && this.mazeData[String(this.lastCheckPt)][data['direction']].indexOf(data['value']) == -1))) {
                this.updateGlobalDirections(pt, false);

                if (pt.position.distanceTo(data['position']) <= (data['value'] !== 2 ? .065 : .01)) {
                    this.events.dispatchEvent({
                        type: "PLAYER_EVENTS",
                        message: {
                            "event_type": "lock_on_checkPoint",
                            "data": {
                                "position": pt.position
                            }
                        }
                    });
                }

                this.lastCheckPt = parseInt(pt.name.split("Plane")[1]);
                console.log(this.lastCheckPt,'lastCheckPt')
                if(this.lastCheckPt == 32){
                    Global.canJump= true;
                    this.events.dispatchEvent({
                        type: "GAMESCENE_EVENTS",
                        message: {
                            "event_type": "update_reached",
                            data: {
                            }
                        }
                    });
                    this.events.dispatchEvent({
                        type: "GAME_EVENTS",
                        message: {
                            "event_type": "disable_control",
                            data: {
                            }
                        }
                    });
                    Global.gameStarted= false;
                    this.events.dispatchEvent({
                        type: "GAME_EVENTS",
                        message: {
                            "event_type": "stop_timer",
                            data: {
                            }
                        }
                    });

                }
                Global.lastCheckPt = this.mazePoints.children[this.lastCheckPt - 1];
                // console.log(this.mazeData[String(this.lastCheckPt)][data['direction']], data['value'],  this.lastCheckPt, ' CHECK>>', Global.lastCheckPt.name)
            }

            if (pt.position.distanceTo(data['position']) <= .065 && (parseInt(pt.name.split("Plane")[1]) == this.lastCheckPt) /* && data['direction'] == Global.lastDirection */ ) {
                this.updateGlobalDirections(pt, true, data['direction']);
            }

            /* 
              if((pt.position.distanceTo(data['position']) <= .04 || data['value'] == 2) && (parseInt(pt.name.split("Plane")[1]) == this.lastCheckPt) ){
                this.updateGlobalDirections(pt, data['value'] !== 2, data['direction']);
            }
            */


        });
        let checkPtKey = `Plane.0${this.lastCheckPt<10?'0':''}${this.lastCheckPt}`;


        // console.log(data['check_diagonal_reset'],' check_diagonal_reset')
        if (Global.lastCheckPt.position.distanceTo(data['position']) >= 0.0 && data['check_diagonal_reset']) {
            console.log(Global.lastCheckPt,' lastCheckPt');
            
            this.events.dispatchEvent({
                type: "PLAYER_EVENTS",
                message: {
                    "event_type": "reset_diagonal_move",
                    "data": {
                        "direction": data['direction'] == 'x' ? 'z' : 'x'
                    }
                }
            });
        }
        // console.log(this.mazePoints.children[this.lastCheckPt-1]['name'],'this.lastCheckPt ',checkPtKey)
        // console.log(data);
    }
    iterate(parent) {
        parent.forEach((child) => {
            if (child.material) {
                child.material.envMap = Global.assets['cubemap']['1']['asset'];
                child.material.envMapIntensity = 1;
                child.material.roughness = 1;
                child.material.metalness = 0;
            }
            if (child.children.length > 0) {
                this.iterate(child.children);
            }
        });
    }
    createScene() {
        this.maze = Global.assets['glbs']['maze']['asset'].scene;
        this.scene.add(this.maze);
        console.log(this.maze,'this.maze')
        /* this.splash= Global.assets['glbs']['splash']['asset'];
        this.splash.scene.scale.set(.001,.001,.001)
        this.scene.add(this.splash.scene);
        console.log(this.splash,' splash');


        this.mixer = new THREE.AnimationMixer(this.splash.scene);
        this.splashAnim = this.mixer.clipAction(this.splash.animations[0])
        this.splashAnim.play(); */

        this.mazePoints = Global.assets['glbs']['points']['asset'].scene;
        
        this.scene.add(this.mazePoints);
        this.mazePoints.children.sort((a, b) => (parseInt(a.name.split("Plane")[1]) > parseInt(b.name.split("Plane")[1])) ? 1 : -1)

        this.mazePoints.position.y -= .1

        Global.startPos = this.mazePoints.children[0/* this.mazePoints.children.length-3 */].position;
        this.iterate(this.maze.children);
        console.log(this.mazePoints , " this.mazePoints")

        Global.lastCheckPt = this.mazePoints.children[this.lastCheckPt - 1];
console.log(Global.lastCheckPt , " Global.lastCheckPt")
        /* this.Milk_Glass = Global.assets['glbs']['Milk_Glass']['asset'].scene;
        this.scene.add(this.Milk_Glass);
        this.Milk_Glass.scale.set(.3, .3, .3);
        this.iterate(this.Milk_Glass.children); */

        this.waterMaterial = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            transparent: true,
            side: THREE.BackSide,
            depthWrite: false,
            uniforms: {
                uTime: {
                    value: 1.0
                }
            }
        });
        this.waterGeo = new THREE.PlaneGeometry(0.4, .45);
        this.water = new THREE.Mesh(this.waterGeo, this.waterMaterial);
        this.water.rotation.x = Math.PI / 2;
        this.water.position.set(-0.005, -.01, .03);
        this.scene.add(this.water);


        this.waterWhite= new THREE.MeshBasicMaterial({
            color: 0xffffff,
            transparent: true,
            opacity:0.9,
            side: THREE.DoubleSide,
            depthWrite:false
        });

        this.whiteGeo= new THREE.PlaneGeometry(0.4, .43);
        this.white= new THREE.Mesh(this.whiteGeo, this.waterWhite);
        this.white.rotation.x= Math.PI/2;
        this.white.position.set(-0.005,-0.022,.03);
        this.scene.add(this.white);


        this.cloud1 = Global.assets['glbs']['Cloud1']['asset'].scene;
        this.scene.add(this.cloud1);
        this.cloud1.scale.set(.1, .1, .1)
        this.cloud1.position.set(.3,.4,1.4)
        this.iterate(this.cloud1.children);

        this.cloud2 = Global.assets['glbs']['Cloud2']['asset'].scene;
        this.scene.add(this.cloud2);
        this.cloud2.scale.set(.15, .15, .15)
        this.cloud2.position.set(.5,-1.0,2.4)
        this.iterate(this.cloud2.children);

        /* this.cloud3 = Global.assets['glbs']['Cloud3']['asset'].scene;
        this.scene.add(this.cloud3);
        this.cloud3.scale.set(.1, .1, .1)
        this.cloud3.position.set(.5,-3.0,.5)
        this.iterate(this.cloud3.children); */


    }
    addParticles(size, parentTrack) {

        this.positions = new Float32Array(this.particleCnt * 3);
        for (let i = 0; i < this.particleCnt; i++) {
            this.positions[i * 3 + 0] = (Math.random() - 0.5) * 1;
            this.positions[i * 3 + 1] = (Math.random() - 0.25) * 0.6;
            this.positions[i * 3 + 2] = (Math.random() - 1.0) * size;
        }
        this.particleGeometry = new THREE.BufferGeometry();
        this.particleGeometry.setAttribute(
            'position',
            new THREE.BufferAttribute(this.positions, 3)
        );
        this.particle = new THREE.Points(this.particleGeometry, this.particleMaterial);
        this.scene.add(this.particle);
        this.particle.position.set(0, 0, this.nextPos);
        this.particles.push(this.particle);
        this.particle.userData.parentTrack = parentTrack;


    }



    update(data) {
        if (this.mixer) {
            console.log("Scene Update")
            this.mixer.update(.014)
        }

        this.waterMaterial && (this.waterMaterial.uniforms.uTime.value += .01);
    }

}