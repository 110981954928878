import * as THREE from 'three'
import EventDispatcher from '../common/EventDispatcher';
import alea from 'alea';
import {gsap, Expo} from 'gsap';


export default class Camera extends THREE.PerspectiveCamera{
    zLerp=new THREE.Vector3();
    yLerp=new THREE.Vector3();
    xLerp=new THREE.Vector3();
    gameStarted=false;
    constructor(fov, ratio, near, far){
        super(fov, ratio, near, far);
    }
    init(){
        this.events= EventDispatcher.getObj();
        this.events.addEventListener("CAMERA_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("PLAYER_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("GAME_EVENTS", this.onEvents.bind(this));


        this.position.set(0.0, 4, .499);
        // this.position.x = .001;//Math.PI/2
        // this.position.z = -.1;
     /*    this.yLerp= new THREE.Vector3(0, 0.15, 0);
        this.zLerp= new THREE.Vector3(0, 0.1, .001);
        this.rotationVal=new THREE.Vector3(0,0,0); */
        this.yLerp = new THREE.Vector3(0, .1, 0);
        this.zLerp = new THREE.Vector3(0, .07, .001);
        this.rotationVal = new THREE.Vector3(0, 0, 0);
        this.xLerp= this.position.clone();
        this.shakeVal= {
            'value': 0.0
        };
        
        this.lookAt(0, 0, 0.5);
        // this.rotation.z += Math.PI/2
        // this.rotation.set(Math.PI/2, Math.PI, 0);
    }
    onEvents(data){
        switch(data['message']['event_type']){
            case "resize":
                this.onResize(data['message']['data']);
                break;
            case "update":
                this.update();
                break;
            /* case "move_player":
                this.smoothFollowPlayer(data['message']['data']);
                break; */
            case "shake_camera":
                this.prepareToShake(data['message']['data']);
                break;
            case "start_game":
                this.gameStarted= true;
                break;
            case "fix_position":
                this.fixPosition(data['message']['data']);
                break;
            case "replay_game":
                this.replayGame();
                break;
        }
    }
    replayGame(){

    }
    onResize(data){
        this.aspect = data.aspect;
        this.updateProjectionMatrix();
    }


   fixPosition(data){
    this.position.lerp(data['temp_position'], 0.02 * .5*2);

    this.lookAt(0,0,0.5);
    // console.log(data['position'].clone())
    // console.log(data['position'],(data['position'] + new THREE.Vector3(0, 0, 0)))
   }
    update(){
        // console.log("AAA")

        // this.position.z =-1.8;//-=0.001;
        // this.position.z -=0.003;
    }
    smoothFollowPlayer(data){
        console.log("SS")
        this.lookAt(new THREE.Vector3(data.position.x, data.position.y + .03, data.position.z - 2)),
        this.zLerp.lerp(new THREE.Vector3(data.position.x, data.position.y - data.speed * 2 + .04, data.position.z + .1 + data.rotation.x * .01), this.gameStarted ? .15 : .01),
        this.yLerp.lerp(new THREE.Vector3(data.position.x, data.position.y - data.speed * 1.5 + .04, data.position.z + .1), this.gameStarted ? .1 : .02),
        this.xLerp.lerp(new THREE.Vector3(data.position.x + data.rotation.z * .05, data.position.y - data.speed * 2 + .04, data.position.z + .1), this.gameStarted ? .02 : .01),
        this.position.set(this.xLerp.x, this.yLerp.y, this.zLerp.z),
        this.position.add(new THREE.Vector3(Math.random() * this.shakeVal.value, Math.random() * this.shakeVal.value, 0)),
        this.rotationVal.lerp(new THREE.Vector3(0, 0, -Math.PI * data.rotation.z * .1), .0015),
        this.rotation.set(this.rotationVal.x, this.rotationVal.y, this.rotationVal.z)


    }
    prepareToShake(data){
        console.log(data['intensity'],'intensity')
        this.shakeVal={
            'value': data['shake_fact']*data['intensity']
        };

        gsap.to(this.shakeVal, { duration: 1, delay: 0, value: 0,repeat:0,ease: Expo.easeOut,onUpdate:function(){

        }.bind(this)});
    }
}