let Global = {
    BLOOM_SCENE: 1,
    itemsToRayCastRight: [],
    itemsToRayCastLeft: [],
    itemsToRayCastDown: [],
    itemsToRayCastUp: [],
    itemsToCollect: [],
    energyTotal: 0,
    playCount: 0,
    scoreTotal: 0,
    startPos: null,
    moveExecuted: false,
    lastDirection: null,
    signedDirection: null,
    lastSignedDirection: null,
    lastMoveDirection: null,
    volumeBool: false,
    firstName: '',
    canUpload: true,
    base64data: '',
    gameTime: 0,
    gameTry: 0,
    image_no: 0,
    rotateTO:null,
    canRotate: true,
    gameKey: null,
    loginStatus: false,
    gender: "male",
    movePossibleDirns: {
        'x': [1],
        'z': [0]
    },
    events: null,
    lastCheckPt: null,
    URL_CREATE: "https://newsfeedsmartapp.com/OreoMaze/api/clicks/webservice.php",
    URL_VUPDATE: "https://newsfeedsmartapp.com/OreoMaze/api/valueUpdator.php",
    URL_USER: "https://newsfeedsmartapp.com/OreoMaze/api/user.php",
    URL_DATA: "https://newsfeedsmartapp.com/OreoMaze/api/webservice.php",
    URL_upload: "https://newsfeedsmartapp.com/OreoMaze/api/upload.php",

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    },
    onResize: function () {
        Global.rotateTO && clearTimeout(Global.rotateTO);
        Global.rotateTO= setTimeout(() => {
            if (Global.viewMode == "landscape" && window.innerWidth < window.innerHeight && !Global.canStrech) {
                Global.readyToPlay = false;

                document.getElementById("rotate").classList.add("active");
                Global.events && Global.events.dispatchEvent({
                    type: "GAME_EVENTS",
                    message: {
                        "event_type": "pause_game",
                        data: {
    
                        }
                    }
                });
            } else if (Global.viewMode == "portrait" && window.innerWidth > window.innerHeight && !Global.canStrech) {

                Global.readyToPlay = false;
                document.getElementById("rotate").classList.add("active");
                Global.events && Global.events.dispatchEvent({
                    type: "GAME_EVENTS",
                    message: {
                        "event_type": "pause_game",
                        data: {
    
                        }
                    }
                });
            } else {
                // Global.readyToPlay = true;
                document.getElementById("rotate").classList.remove("active");
                Global.events && Global.events.dispatchEvent({
                    type: "GAME_EVENTS",
                    message: {
                        "event_type": "resume_game",
                        data: {
    
                        }
                    }
                });
                /*  if(Global.waitingToPlay && Global.waitingToPlayCb){
                     Global.waitingToPlay=false;
                     Global.waitingToPlayCb();
                 } */
            }
        }, 350);
        // if(Global.canRotate){
        
        // }

    },
    buttonClick() {
        document.querySelector('#Click_Music').play();
    },
    recieptStatusUpdate() {
        document.querySelector('#reciptStaus').style.width = '6%'

        if (Global.image_no > 0) {
            document.querySelector('#reciptStaus').src = `${window.baseURL}/assets/Receipt-uploaded_Icon.png`
        } else {
            document.querySelector('#reciptStaus').src = `${window.baseURL}/assets/Receipt-not-uploaded_Icon.png`
            let clearTimer = setTimeout(() => {
                document.querySelector('#reciptStaus').src = `${window.baseURL}/assets/Receipt-not-uploaded.png`
                document.querySelector('#reciptStaus').style.width = '30%'
            }, 500);
        }
    },
    clickUpdater(tName, clm) {


        $.ajax({
            type: 'POST',
            url: Global.URL_VUPDATE,
            data: {
                tName: tName,
                clm: clm,
                uid: Global.U_ID,

            },
            dataType: "json",
            success: function (resultData) {


            },
            // error: function(err) { console.log(err) }
        });
    },
    loadingScreen2(page1='',page2,page3='') {
        document.querySelector('#loadingScren2').style.display = 'flex';
        let tim = setTimeout(() => {
            clearTimeout(tim)
            document.querySelector('.loadingScrenContainer2').style.left = 0

        }, 20);
         let clearTimeer = setTimeout(() => {
            clearTimeout(clearTimeer)
            document.querySelector('.loadingScrenContainer2').style.left = '-250%'
            if(page1==''){
                $('.pages').hide()

            }else{
                document.querySelector('#'+page1).style.display = 'none';

            }
            if(page3!=''){
                document.querySelector('#'+page3).style.display = 'none';
            }
            document.querySelector('#'+page2).style.display = 'flex';

            let clearTimeer2 = setTimeout(() => {
                clearTimeout(clearTimeer2)
                document.querySelector('#loadingScren2').style.display = 'none';
                document.querySelector('.loadingScrenContainer2').style.left = '250%'
            }, 600);
        }, 600);
    },
    loadingScreen3() {
        var ccc = 1
        document.querySelector('#loadingScren').style.display = 'flex';
        document.querySelector("#coocke").className = `sequence-0}`
        document.querySelector('#loadingScren').style.transition = '0s';

        document.querySelector('#loadingScren').style.opacity = 1;

        // let clearTimer=setTimeout(() => {
        //     clearTimeout(clearTimer)
        //     document.querySelector('#loadingScren').style.opacity=1;
        // }, 0);

        var looper = setInterval(() => {
            ccc += 1;
            // if (ccc === 22) ccc = 1;
            document.querySelector("#coocke").className = `sequence-${ccc}`

            if (ccc === 20) {
                clearInterval(looper)
                // clearTimeout(stoper)
                document.querySelector('#scorePage').style.display = 'flex';

                document.querySelector('#loadingScren').style.transition = '0.5s';

                document.querySelector('#loadingScren').style.opacity = 0;

                let clearTimer = setTimeout(() => {
                    clearTimeout(clearTimer)
                    document.querySelector('#loadingScren').style.display = 'none';
                }, 400);
            }
        }, 70);
        // var stoper = setTimeout(() => {
        //     clearInterval(looper)
        //     clearTimeout(stoper)
        //     document.querySelector('#loadingScren').style.display='none';

        // }, 2000);


    },
    loadingScreen() {
        document.querySelector(".dot3").classList.remove("removeactiveScale");
        document.querySelector(".dot1").classList.add("activeScale");
        var def = setTimeout(() => {
            clearTimeout(def);
            document.querySelector(".dot2").classList.add("activeScale");
            def = setTimeout(() => {
                clearTimeout(def);
                document.querySelector(".dot3").classList.add("activeScale");
                def = setTimeout(() => {
                    clearTimeout(def);
                    /* document.querySelector(".dot3").classList.remove("activeScale"); */
                    def = setTimeout(() => {
                        clearTimeout(def);
                        document.querySelector(".dot3").classList.add("removeactiveScale");
                        def = setTimeout(() => {
                            clearTimeout(def);
                            document.querySelector(".dot2").classList.remove("activeScale");
                            def = setTimeout(() => {
                                clearTimeout(def);
                                /* document.querySelector(".dot3").classList.remove("removeactiveScale"); */
                                document.querySelector(".dot1").classList.remove("activeScale");
                            }, 50);
                        }, 100);
                    }, 50);
                }, 1005);
            }, 50);
        }, 100);

    },
    assets: {
        "cubemap": {
            "0": {
                'key': 'environmentMapTexture',
                "path": [
                    `${window.baseURL}/textures/environmentMaps/0/px.png`,
                    `${window.baseURL}/textures/environmentMaps/0/nx.png`,
                    `${window.baseURL}/textures/environmentMaps/0/py.png`,
                    `${window.baseURL}/textures/environmentMaps/0/ny.png`,
                    `${window.baseURL}/textures/environmentMaps/0/pz.png`,
                    `${window.baseURL}/textures/environmentMaps/0/nz.png`
                ],
                "asset": null
            },
            "1": {
                'key': 'environmentMapTexture',
                "path": [
                    `${window.baseURL}/textures/environmentMaps/1/px.png`,
                    `${window.baseURL}/textures/environmentMaps/1/nx.png`,
                    `${window.baseURL}/textures/environmentMaps/1/py.png`,
                    `${window.baseURL}/textures/environmentMaps/1/ny.png`,
                    `${window.baseURL}/textures/environmentMaps/1/pz.png`,
                    `${window.baseURL}/textures/environmentMaps/1/nz.png`
                ],
                "asset": null
            }
        },
        "glbs": {

            "Milk_Glass": {
                'key': 'Milk_Glass',
                "path": [
                    `${window.baseURL}/models/Maze/Milk_Glass.glb`
                ],
                "asset": null
            },
            "Cloud1": {
                'key': 'Cloud1',
                "path": [
                    `${window.baseURL}/models/Clouds/Cloud1.gltf`
                ],
                "asset": null
            },
            "Cloud2": {
                'key': 'Cloud2',
                "path": [
                    `${window.baseURL}/models/Clouds/Cloud2.gltf`
                ],
                "asset": null
            },
            "Cloud3": {
                'key': 'Cloud3',
                "path": [
                    `${window.baseURL}/models/Clouds/Cloud3.gltf`
                ],
                "asset": null
            },
            "Cloud4": {
                'key': 'Cloud4',
                "path": [
                    `${window.baseURL}/models/Clouds/Cloud4.gltf`
                ],
                "asset": null
            },
            "splash": {
                'key': 'maze',
                "path": [
                    `${window.baseURL}/models/Maze/Splash.glb`
                ],
                "asset": null
            },
            "maze": {
                'key': 'maze',
                "path": [
                    `${window.baseURL}/models/Maze/Maze.gltf`
                ],
                "asset": null
            },
            "points": {
                'key': 'points',
                "path": [
                    `${window.baseURL}/models/Maze/Points.gltf`
                ],
                "asset": null
            },
            "character_male": {
                'key': 'character_male',
                "path": [
                    `${window.baseURL}/models/Character/Male/Character_animated.gltf`
                ],
                "asset": null
            },
            "character_female": {
                'key': 'character_female',
                "path": [
                    `${window.baseURL}/models/Character/Female/Character_Female.gltf`
                ],
                "asset": null
            }
        },
        "textures": {

        }
    },

    popUpOff() {
        // document.querySelector("#popupPage").style.top = "-100%";
        // $('input').val('')
        // $('select').val('')
        // document.querySelector('.prifix').innerHTML=''
        // document.querySelector('.prifix').style.display='none'
        // document.querySelector('.prifixLogin').innerHTML=''
        // document.querySelector('.prifixLogin').style.display='none'
        document.querySelector(".popupPageContainer").style.top = "-50%";
        setTimeout(() => {
            document.querySelector("#popupPage").style.opacity = 0;
        }, 500);
        setTimeout(() => {
            document.querySelector("#popupPage").style.display = "none";
            document.querySelector('#homePage').classList.remove('pevents')

            // document.querySelector(".popupPageContainer").style.top = "50%";
            // document.querySelector("#popupPage").style.top = "-100%";
        }, 1500);
    },
    popupOn() {
        document.querySelector("#popupPage").style.display = "block";
        document.querySelector('#homePage').classList.add('pevents')
        setTimeout(() => {
            document.querySelector("#popupPage").style.opacity = 1;

        }, 10);
        setTimeout(() => {
            document.querySelector(".popupPageContainer").style.top = "50%";

        }, 500);
        setTimeout(() => {
            document.querySelector("#popupPage").style.opacity = 1;
        }, 1);
        setTimeout(() => {
            document.querySelector(".popupPageContainer").style.top = "50%";
            // Global.popupSound.play()
        }, 500);

    },
    homePageAnimation(t = 800) {
        document.querySelector('#gamePlay').style.top = '100vh'
        document.querySelector('#photoUpload').style.top = '100vh'
        document.querySelector('#nameContainer').style.top = '-20%'

        let clearTime = setTimeout(() => {
            clearTimeout(clearTime)
            document.querySelector('#gamePlay').style.top = '0'
            document.querySelector('#photoUpload').style.top = '0'
            document.querySelector('#nameContainer').style.top = '5%'

        }, t);

    }
}
export {
    Global
}