import * as THREE from 'three'

export default class Lights{
    constructor(scene){
        this.scene=scene;
    }
    init(){
        /* this.ambientLight = new THREE.AmbientLight(0xffffff, 0.0)
        this.scene.add(this.ambientLight)
    
        this.directionalLight = new THREE.DirectionalLight(0xffffff, 10.2)
        this.directionalLight.position.set(2, 3, 0);
        this.scene.add(this.directionalLight); */


        
  
    }
}