
import EventDispatcher from "../common/EventDispatcher";
import { Global } from "../common/global";
import {
    JoystickController
} from './JoyStick';

let direction=null;
export default class GameControl{
    constructor(scene){
        this.scene= scene;

        document.addEventListener('keydown', this.onKeyDown.bind(this));
        document.addEventListener('keyup', this.onKeyUp.bind(this));
    }
    init(){
        this.directions=[];
        this.gameOver=false;
        this.gameStarted= false;

        this.events= EventDispatcher.getObj();
        this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("GAME_EVENTS", this.onGameEvents.bind(this));

        
       
    }
    onGameEvents(data){
        switch (data['message']['event_type']) {
            case "replay_game":
                this.replayGame();
                break;
            case "gyro_control_add":
                console.log(data['message']['data'],'Add Control Gyro');
                this.onKeyDown(data['message']['data']);
                break;
            case "gyro_control_remove":
                this.onKeyUp(data['message']['data']);
                break;
            case "disable_control":
                this.disableControl();
                break;
                case "reset_last_move":
                    this.resetLastMove(data['message']['data']);
                    break;
        }
    }
    disableControl(){
        this.gameOver= true;
        this.gameStarted= false;
        this.directions=[];
    }
    replayGame(){
        this.gameOver= false;
        this.gameStarted= true;
    }
    onEvents(data){
        switch(data['message']['event_type']){
            case "update":
                this.update();
                break;
            case "game_over":
                this.gameOver=true;
                this.gameStarted=false;
                this.directions=[];
                break;
            case "start_game":
                this.startGame();
                break;
   
        }
    }
    startGame(){
        setTimeout(() => {
            this.gameStarted= true;
            let myStick = new JoystickController("stick",40, 8);

        }, 500)
    }
    onKeyDown(event){
        if(!this.gameStarted || this.gameOver) return false;

        if((event.code=="ArrowLeft" || event.code=="KeyA") && this.directions.indexOf('left') == -1){
            this.directions.push('left');
        }else if((event.code=="ArrowRight" || event.code=="KeyD") && this.directions.indexOf('right') == -1){
            this.directions.push('right');
        }
        if((event.code=="ArrowUp" || event.code=="KeyW") && this.directions.indexOf('up') == -1){
            this.directions.push('up');
        }else if((event.code=="ArrowDown" || event.code=="KeyS") && this.directions.indexOf('down') == -1){
            this.directions.push('down');
        }
    };
    onKeyUp(event){
        if(!this.gameStarted || this.gameOver) return false;

        if((event.code=="ArrowLeft" || event.code=="KeyA") && this.directions.indexOf('left') !== -1){
            this.directions.splice(this.directions.indexOf('left'),1);
        }else if((event.code=="ArrowRight" || event.code=="KeyD") && this.directions.indexOf('right') !== -1){
            this.directions.splice(this.directions.indexOf('right'),1);
        }
        if((event.code=="ArrowUp" || event.code=="KeyW") && this.directions.indexOf('up') !== -1){
            this.directions.splice(this.directions.indexOf('up'),1);
        }else if((event.code=="ArrowDown" || event.code=="KeyS") && this.directions.indexOf('down') !== -1){
            this.directions.splice(this.directions.indexOf('down'),1);
        }
        /* if(this.directions.length == 0){
            console.log("RESET>>>>");
            this.events.dispatchEvent({
                type: "PLAYER_EVENTS",
                message: {
                    "event_type": "notify_global_directions",
                    "data": {
                    }
                }
            });
        } */
        this.events.dispatchEvent({
            type: "PLAYER_EVENTS",
            message: {
                "event_type": "notify_global_directions",
                "data": {
                }
            }
        });
    };
    resetLastMove(resetDir){
        console.log('resetLastMove')
        if(resetDir['direction']){
            direction=resetDir['direction']
        }
        console.log(resetDir['direction'],'Remove Control Gyro');
        switch(direction){
            case "right":
                this.onKeyUp({
                    "code": "ArrowRight"
                });
                break;
            case "left":
                this.onKeyUp({
                    "code": "ArrowLeft"
                });
                break;
            case "up":
                this.onKeyUp({
                    "code": "ArrowUp"
                });
                break;
            case "down":
                this.onKeyUp({
                    "code": "ArrowDown"
                });
                break;
        }
    }
    update(dt){

        let dir=null;
        let prevDir=null;
        let forceX=0;
        let forceZ=0;
        let dirnCnt=0;
        if(!this.gameStarted) return false;

        if(this.directions.length == 0 && Global.moveExecuted){
            this.events.dispatchEvent({
                type: "PLAYER_EVENTS",
                message: {
                    "event_type": "force_idle",
                    "data": {
                    }
                }
            });
        }
        if(this.directions.length>0){
            let _val= this.directions[this.directions.length-1];
            Global.lastDirection= (_val== "left" || _val == "right")?'x':'z';
            
        }
        Global.moveExecuted= false;
        
        if(this.directions.length>0 && !this.gameOver){
            for(let i=this.directions.length-1;i>=0;i--){
                // if(dirnCnt>=1) return false;
                if(!Global.moveExecuted){
                    dir=this.directions[i]
                    if((dir=="left" || dir=="right") && (prevDir!="left" && prevDir!="right")){
                        forceX = (dir=="right"?1:dir=="left"?-1:0);
                    }
                    if((dir=="up" || dir=="down") && (prevDir!="up" && prevDir!="down")){
                        forceZ = (dir=="up"?1:dir=="down"?-1:0);
                    }
                    dirnCnt++;
                    prevDir=dir;
                }
              
                
            }
            // console.log(forceX, forceZ,' force');
          
            this.events.dispatchEvent({
                type: "GAMECONTROL_EVENTS",
                message: {
                    "event_type": "force",
                    "data": {
                        forceX,
                        forceZ
                    }
                }
            });
        }
        if(this.directions.length>0 && !Global.moveExecuted){
            this.events.dispatchEvent({
                type: "PLAYER_EVENTS",
                message: {
                    "event_type": "force_idle",
                    "data": {
                    }
                }
            });
        }
        
        this.events.dispatchEvent({
            type: "GAMECONTROL_EVENTS",
            message: {
                "event_type": "reset_force",
                "data": {
                    'moveX': (this.directions.length>0 ? (this.directions[this.directions.length-1]=='left' || this.directions[this.directions.length-1]=='right'):false),
                    'moveY': (this.directions.length>0 ? (this.directions[this.directions.length-1]=='up' || this.directions[this.directions.length-1]=='down'):false)
                }
            }
        });
    };
  
}
