import EventDispatcher from "../common/EventDispatcher";

class JoystickController
{
  constructor( stickID, maxDistance, deadzone )
  {
    this.id = stickID;
    let stick = document.getElementById(stickID);
    // location from which drag begins, used to calculate offsets
    this.dragStart = null;
    // track touch identifier in case multiple joysticks present
    this.touchId = null;
    
    this.active = false;
    this.value = { x: 0, y: 0 }; 
    let self = this;

    this.prevDirection=null;
    this.nextDirection=null;
    this.events= EventDispatcher.getObj();

    function handleDown(event)
    {
        document.querySelector("#joystick").classList.add("active")
        self.active = true;
      // all drag movements are instantaneous
      stick.style.transition = '0s';
      // touch event fired before mouse event; prevent redundant mouse event from firing
      event.preventDefault();
        if (event.changedTouches)
          self.dragStart = { x: event.changedTouches[0].clientX, y: event.changedTouches[0].clientY };
        else
          self.dragStart = { x: event.clientX, y: event.clientY };
      // if this is a touch event, keep track of which one
        if (event.changedTouches)
          self.touchId = event.changedTouches[0].identifier;
    }
    
    function handleMove(event) 
    {
        if ( !self.active ) return;
        // if this is a touch event, make sure it is the right one
        // also handle multiple simultaneous touchmove events
        let touchmoveId = null;
        if (event.changedTouches)
        {
          for (let i = 0; i < event.changedTouches.length; i++)
          {
            if (self.touchId == event.changedTouches[i].identifier)
            {
              touchmoveId = i;
              event.clientX = event.changedTouches[i].clientX;
              event.clientY = event.changedTouches[i].clientY;
            }
          }
          if (touchmoveId == null) return;
        }
        const xDiff = event.clientX - self.dragStart.x;
        const yDiff = event.clientY - self.dragStart.y;
        const angle = Math.atan2(yDiff, xDiff);
      const distance = Math.min(maxDistance, Math.hypot(xDiff, yDiff));
      const xPosition = distance * Math.cos(angle);
      const yPosition = distance * Math.sin(angle);
      // move stick image to new position
        stick.style.transform = `translate3d(${xPosition}px, ${yPosition}px, 0px)`;
      // deadzone adjustment
      const distance2 = (distance < deadzone) ? 0 : maxDistance / (maxDistance - deadzone) * (distance - deadzone);
        const xPosition2 = distance2 * Math.cos(angle);
      const yPosition2 = distance2 * Math.sin(angle);
        const xPercent = parseFloat((xPosition2 / maxDistance).toFixed(4));
        const yPercent = parseFloat((yPosition2 / maxDistance).toFixed(4));
        
        self.value = { x: xPercent, y: yPercent };
        // console.log(self.value,'self.value')
       /*  if(Math.abs(self.value.x)>Math.abs(self.value.y)){
            // console.log("Move X")
            if(Math.sign(self.value.x)==-1){
                self.nextDirection1='left'
            }else{
                self.nextDirection1='right'
            }
        }else{
            if(Math.sign(self.value.y)==-1){
                self.nextDirection2='up'
            }else{
                self.nextDirection2='down'
            }
        } */
       if(Math.abs(self.value.x)>0.4){
        if(Math.sign(self.value.x)==-1){
            self.nextDirection1='left'
        }else{
            self.nextDirection1='right'
        }
       }else{
        self.nextDirection1=null;
       }
       if(Math.abs(self.value.y)>0.4){
        if(Math.sign(self.value.y)==-1){
            self.nextDirection2='up'
        }else{
            self.nextDirection2='down'
        }
       }else{
        self.nextDirection2=null;
       }
        if(self.nextDirection1!=self.prevDirection1){
            if(self.prevDirection1!=null){
                // console.log("Reset : ",self.prevDirection1)
                self.events.dispatchEvent({
                    type: "GAME_EVENTS",
                    message: {
                        "event_type": "reset_last_move",
                        "data": {
                            'direction': self.prevDirection1
                        }
                    }
                });
                // self.emitter.emit('control:reset_last_move',);
            }
            // console.log(self.emitter,'self.emitter')
            switch(self.nextDirection1){
                case "right":
                   /*  self.emitter.emit('control:on_down',{
                        "key": "ArrowRight"
                    }); */
                    self.events.dispatchEvent({
                        type: "GAME_EVENTS",
                        message: {
                            "event_type": "gyro_control_add",
                            data: {
                                "code": "ArrowRight"
                            }
                        }
                    });
                    break;
                case "left":
                    self.events.dispatchEvent({
                        type: "GAME_EVENTS",
                        message: {
                            "event_type": "gyro_control_add",
                            data: {
                                "code": "ArrowLeft"
                            }
                        }
                    });
                    break;
                case "up":
                    self.events.dispatchEvent({
                        type: "GAME_EVENTS",
                        message: {
                            "event_type": "gyro_control_add",
                            data: {
                                "code": "ArrowUp"
                            }
                        }
                    });
                    break;
                case "down":
                    self.events.dispatchEvent({
                        type: "GAME_EVENTS",
                        message: {
                            "event_type": "gyro_control_add",
                            data: {
                                "code": "ArrowDown"
                            }
                        }
                    });
                    break;
            }
    
        }
        if(self.nextDirection2!=self.prevDirection2){
            if(self.prevDirection2!=null){
                // console.log("Reset : ",self.prevDirection2)
                self.events.dispatchEvent({
                    type: "GAME_EVENTS",
                    message: {
                        "event_type": "reset_last_move",
                        "data": {
                            'direction': self.prevDirection2
                        }
                    }
                });
                // self.emitter.emit('control:reset_last_move',self.prevDirection2);
            }
            // console.log(self.emitter,'self.emitter')
            switch(self.nextDirection2){
                case "right":
                    self.events.dispatchEvent({
                        type: "GAME_EVENTS",
                        message: {
                            "event_type": "gyro_control_add",
                            data: {
                                "code": "ArrowRight"
                            }
                        }
                    });
                    break;
                case "left":
                    self.events.dispatchEvent({
                        type: "GAME_EVENTS",
                        message: {
                            "event_type": "gyro_control_add",
                            data: {
                                "code": "ArrowLeft"
                            }
                        }
                    });
                    break;
                case "up":
                    self.events.dispatchEvent({
                        type: "GAME_EVENTS",
                        message: {
                            "event_type": "gyro_control_add",
                            data: {
                                "code": "ArrowUp"
                            }
                        }
                    });
                    break;
                case "down":
                    self.events.dispatchEvent({
                        type: "GAME_EVENTS",
                        message: {
                            "event_type": "gyro_control_add",
                            data: {
                                "code": "ArrowDown"
                            }
                        }
                    });
                    break;
            }
    
        }

        self.prevDirection1= self.nextDirection1;
        self.prevDirection2= self.nextDirection2;
      }
    function handleUp(event) 
    {

        document.querySelector("#joystick").classList.remove("active")
        if ( !self.active ) return;

        
        // if this is a touch event, make sure it is the right one
        if (event.changedTouches && self.touchId != event.changedTouches[0].identifier) return;
        // transition the joystick position back to center
        stick.style.transition = '.2s';
        stick.style.transform = `translate3d(0px, 0px, 0px)`;
        // reset everything
        self.value = { x: 0, y: 0 };
        self.touchId = null;
        self.active = false;

        self.events.dispatchEvent({
            type: "GAME_EVENTS",
            message: {
                "event_type": "reset_last_move",
                "data": {
                    'direction': self.nextDirection1
                }
            }
        });
        self.events.dispatchEvent({
            type: "GAME_EVENTS",
            message: {
                "event_type": "reset_last_move",
                "data": {
                    'direction': self.prevDirection2
                }
            }
        });
        /* switch(direction){
            case "right":
                this.onDown({
                    "key": "ArrowRight"
                });
                break;
            case "left":
                this.onDown({
                    "key": "ArrowLeft"
                });
                break;
            case "up":
                this.onDown({
                    "key": "ArrowUp"
                });
                break;
            case "down":
                this.onDown({
                    "key": "ArrowDown"
                });
                break;
        } */

    }
    stick.addEventListener('mousedown', handleDown);
    stick.addEventListener('touchstart', handleDown);
    document.addEventListener('mousemove', handleMove.bind(this), {passive: false});
    document.addEventListener('touchmove', handleMove.bind(this), {passive: false});
    document.addEventListener('mouseup', handleUp);
    document.addEventListener('touchend', handleUp);
  }
}


export{
    JoystickController
}