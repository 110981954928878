import * as THREE from 'three'
import { Global } from '../common/global';

import EventDispatcher from "../common/EventDispatcher";
import {
    gsap,
    Cubic,
    Expo,
    Linear
} from 'gsap';

export default class Character {
    constructor(scene, camera){
        this.scene=scene;
        this.camera= camera;

        this.lockTwn= null;
       this.character=null;
       this.state="idle";
       this.goal= new THREE.Vector3(1,1,3);
       this.temp = new THREE.Vector3(0, 2.55*.3,.49);//new THREE.Vector3;
        this.gameReady= false;
        this.cameraUpdateCnt=0;
       this.rotationVec= new THREE.Vector3(0, Math.PI, 0);
       this.rotateFactY= Math.PI;
       this.lockInProgress= false;
        this.isJumping=false;
    }
    init() {
        this.speedMax = 0.004;
        Global.lastSignedDirection= 'down';

        this.lastDelta= new THREE.Vector3();
        this.events = EventDispatcher.getObj();
        this.events.addEventListener("PLAYER_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("GAMESCENE_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("GAMECONTROL_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("GAME_EVENTS", this.onEvents.bind(this));

    }
    onEvents(data) {
        switch (data['message']['event_type']) {
            case "add_character":
                this.addCharacter();
                break;
            case "force":
                this.move(data['message']['data']);
                break;
            case "lock_on_checkPoint":
                this.lockToCheckPoint(data['message']['data']);
                break;
            case "reset_diagonal_move":
                this.resetDiagonalMove(data['message']['data']);
                break;
            case "update":
                this.update(data['message']['data']);
                break;
            case "force_idle":
                this.showIdle();
                break;
            case "notify_global_directions":
                this.notifyToUpdateGlobalDirections();
                break;
            case "start_game":
                this.startGame();
                break;
            case "jump_to_pool":
                this.jumpToPool();
                break;
            case "update_reached":
                this.updateReached();
                break;
            case "replay_game":
                this.replayGame();
                break;
        }
    }
    replayGame(){
        this.lockInProgress= false;
        this.rotationVec= new THREE.Vector3(0, Math.PI, 0);
        this.character.scene.position.set(Global.startPos.x, Global.startPos.y, Global.startPos.z);
        this.character.scene.rotation.set(this.rotationVec.x, this.rotationVec.y, this.rotationVec.z);
        this.showIdle();
        this.resetReached();

        console.log(Global.startPos ,"Global.startPos 3" ,  this.character.scene.position)
    }
    startGame(){
        this.gameReady= true;
        this.isReached= false;
    }
    notifyToUpdateGlobalDirections(){

        this.events.dispatchEvent({
            type: "GAMESCENE_EVENTS",
            message: {
                "event_type": "update_global_directions",
                "data": {
                    "position": this.character.scene.position
                }
            }
        });
        this.notifyMove(Global.lastMoveDirection, 2, false);
       
    }
    resetReached(){
        this.isReached= false;
    }
    updateReached(){
        this.isReached= true;
    }
    jumpToPool(){
        this.walkAnim.stop();
        this.idleAnim.stop();
        setTimeout(() => {
            this.jumpAnim.play();
            // document.querySelector("#playBtn").classList.add("active");
        }, 250)
        this.isJumping=true;
        this.jumpTwn1= gsap.to(this.character.scene.position, {
            duration: 0.5,
            delay: .4 + .25,
            // x: data['position']['x'],
            z: `-=${.1}`,
            repeat: 0,
        });
        this.jumpTwn2= gsap.to(this.character.scene.position, {
            duration: 0.75,
            delay: .4 + .25,
            // x: data['position']['x'],
            y: `-=${.25}`,
            yoyo:true,
            repeat: 0,
            onComplete: this.showIdle.bind(this)
        });
        document.querySelector('#win_Music').play()
        document.querySelector('#GameBG_Music').pause()
        Global.serverObj.send(
            Global.URL_DATA,
            (data) => {
                var data=JSON.parse(data)
                if (data.code == 200) {
                    setTimeout(() => {
                        if(Global.gameTime<10){
            
                            document.querySelector('#numberOne').src='./assets/0.png'
                            document.querySelector('#numberTwo').src=`./assets/${Global.gameTime}.png`
                
                        }
                        else{
            
                            let sec=Global.gameTime % 10
                            let min=Global.gameTime / 10
                            document.querySelector('#numberOne').src=`./assets/${parseInt(min)}.png`
                            document.querySelector('#numberTwo').src=`./assets/${sec}.png`
                        }
                        document.querySelector('#BG_Music').currentTime=0;
                        document.querySelector('#BG_Music').play()
                        // document.querySelector('#scorePage').style.display = 'flex';

                        Global.canRotate=true;
                        document.querySelector('.win').style.display = 'block';
                        document.querySelector('.loss').style.display = 'none';
                            document.querySelector('.container').style.display = 'flex';
                            Global.loadingScreen3()
            
                    }, 2000);

                }
               
                else {
                    localStorage.clear();
                    window.location.href = "https://www.oreoplayfuldunks.com/?utm_source=QR";
                }
            },
            null,
            {
                saveType: "saveScore",
                timeTaken: Global.gameTime,
                uid: Global.U_ID,
                status: 1,
                gameTry:Global.gameTry,
                phone:Global.mobile
            },
            "POST",
            null,
            false
        );
        
       
    }
    showIdle(){
        if(this.lockInProgress) return false;

        this.isJumping=false;
        this.jumpAnim.stop();
        this.walkAnim.stop();
        this.idleAnim.play();
        this.state= "idle"
        
    }
    notifyMove(direction, value, check){
        this.events.dispatchEvent({
            type: "PLAYER_EVENTS",
            message: {
                "event_type": "move_player",
                "data": {
                    "position": this.character.scene.position,
                    "direction": direction, 
                    "value": value,
                    "check_diagonal_reset": check
                }
            }
        });
    }
    lockToCheckPoint(data){
        // alert("lockToCheckPoint")
        this.lockInProgress=true;
        this.lockTwn= gsap.to(this.character.scene.position, {
            duration: 0.2,
            delay: 0,
            x: data['position']['x'],
            z: data['position']['z'],
            repeat: 0,
            ease: "none",
            onComplete: function(){
                this.lockInProgress=false;
                this.events.dispatchEvent({
                    type: "PLAYER_EVENTS",
                    message: {
                        "event_type": "force_idle",
                        "data": {
                        }
                    }
                });
                if(Global.canJump){
                    Global.canJump=false;
                    this.events.dispatchEvent({
                        type: "GAMESCENE_EVENTS",
                        message: {
                            "event_type": "jump_to_pool",
                            data: {
                            }
                        }
                    });
                }
            }.bind(this)
        })
    }
    resetDiagonalMove(data){

        Global.movePossibleDirns[data['direction']]= [0];
    }
    move(data){

        if(data['forceX'] != 0){
            if(Global.movePossibleDirns['x'].indexOf(data['forceX']) != -1){
                if(this.state != "walking"){
                    this.state= "walking";
                    this.walkAnim.play();

                }
                Global.signedDirection= Math.sign(data['forceX']) == -1?"left":"right";
                Global.lastMoveDirection='x';
                Global.moveExecuted= true;
                this.lockTwn && (gsap.killTweensOf(this.character.scene.position), this.lockTwn=null, this.lockInProgress=false)
                this.character.scene.position.x -= .0025 * data['forceX'];

         /*        this.rotationVec.lerp(new THREE.Vector3(0, -1*Math.sign(data['forceX'])* Math.PI/2, 0, 0), .1)
                this.character.scene.rotation.set(this.rotationVec.x, this.rotationVec.y, this.rotationVec.z); */

                
                let prevPos= this.character.scene.position.clone();
                this.character.scene.position.lerp(new THREE.Vector3(prevPos.x, prevPos.y,  Global.lastCheckPt.position.z), 0.1)
                // Global.movePossibleDirns['z']= [0];
                // console.log(Global.movePossibleDirns['z'], ' check_diagonal_reset')
                this.notifyMove('x', data['forceX'] * 1, !(Global.movePossibleDirns['z'][0] == 0 && Global.movePossibleDirns['z'].length == 1));
                if(Global.movePossibleDirns['x'].indexOf(data['forceX'] * -1) == -1){
                    Global.movePossibleDirns['x'].push(data['forceX'] * -1)
                }
            }
        }
        if(data['forceZ'] != 0){
            if(Global.movePossibleDirns['z'].indexOf(data['forceZ']) != -1){
                if(this.state != "walking"){
                    this.state= "walking";
                    this.walkAnim.play();
                }
                Global.signedDirection= Math.sign(data['forceZ'])==1?"up":"down";
                Global.lastMoveDirection='z';
                Global.moveExecuted= true;
                this.lockTwn && (gsap.killTweensOf(this.character.scene.position), this.lockTwn=null, this.lockInProgress=false)
                this.character.scene.position.z += .0025 * data['forceZ'];
         /*        this.rotationVec.lerp(new THREE.Vector3(0, Math.sign(data['forceZ']) == -1?Math.PI:0, 0), .1)
                
                this.character.scene.rotation.set(this.rotationVec.x, this.rotationVec.y, this.rotationVec.z); */
               
                let prevPos= this.character.scene.position.clone();
                this.character.scene.position.lerp(new THREE.Vector3(Global.lastCheckPt.position.x, prevPos.y,  prevPos.z), 0.1)

                this.notifyMove('z', data['forceZ'] * 1, !(Global.movePossibleDirns['x'][0] == 0 && Global.movePossibleDirns['x'].length == 1));
                if(Global.movePossibleDirns['z'].indexOf(data['forceZ'] * -1) == -1){
                    Global.movePossibleDirns['z'].push(data['forceZ'] * -1)
                }
            }
        }
        if(Global.lastSignedDirection != Global.signedDirection){
            if(Global.signedDirection == 'right'){
                this.rotateFactY += (Global.lastSignedDirection == 'down' )?Math.PI/2:Global.lastSignedDirection == 'up'?-Math.PI/2:(Global.lastSignedDirection == 'left')?Math.PI:-Math.PI/2;
            }
            if(Global.signedDirection == 'left'){
                this.rotateFactY += (Global.lastSignedDirection == 'down')?-Math.PI/2:Global.lastSignedDirection == 'up'?Math.PI/2:(Global.lastSignedDirection == 'right')?Math.PI:-Math.PI/2;
            }
            if(Global.signedDirection == 'up'){
                this.rotateFactY += (Global.lastSignedDirection == 'right')?Math.PI/2:Global.lastSignedDirection == 'left'?-Math.PI/2:(Global.lastSignedDirection == 'down')?Math.PI:-Math.PI/2;
            }
            if(Global.signedDirection == 'down'){
                this.rotateFactY += (Global.lastSignedDirection == 'left')?Math.PI/2:Global.lastSignedDirection == 'right'?-Math.PI/2:(Global.lastSignedDirection == 'up')?Math.PI:-Math.PI/2;
            }
        }
        Global.moveExecuted && (Global.lastSignedDirection= Global.signedDirection);
    }
    update(){
        if(this.mixer)
        {
            this.mixer.update(.025*.5*(this.isJumping?1.25:2))
        }
        // this.cameraUpdateCnt++;
        if(this.character && (this.gameReady || this.cameraUpdateCnt > 200)){
           
            this.rotationVec.lerp(new THREE.Vector3(0, this.rotateFactY, 0), .1*.5*2)
            this.character.scene.rotation.set(this.rotationVec.x, this.rotationVec.y, this.rotationVec.z);
            // Global.moveExecuted= true;
            this.events.dispatchEvent({
                type: "CAMERA_EVENTS",
                message: {
                    "event_type": "fix_position",
                    "data": {
                        "temp_position": this.temp,
                        "position": this.character.scene.position
                    }
                }
            });
            this.temp.y= 2.55*(this.isReached?.4:1)
            this.temp.z= Math.min(-1.25,this.character.scene.position.z-1)*1.25*(this.isReached?.4:1);//*.9;
            this.temp.x= Math.max(-0.8,this.character.scene.position.x-.085)*2*(this.isReached?.4:1);//*1.2
            // console.log( Math.max(-0.8,this.character.scene.position.x-.085),' tempX')
        }
        
    }
    iterate(parent){
        parent.forEach((child) => {

            if(child.material){
                child.material.envMap= Global.assets['cubemap']['1']['asset'];
                child.material.envMapIntensity = 1;
            }
            if(child.children.length>0){
                this.iterate(child.children);
            }
        });
    }
    addCharacter(){
        this.character && (this.scene.remove(this.character.scene),this.character=null);
        this.character= Global.assets['glbs'][`character_${Global.gender}`]['asset'];
        console.log(Global.gender,"Global.gender")

        this.character.scene.scale.set(0.75, 0.75, 0.75);
        this.scene.add(this.character.scene);

        this.goal = new THREE.Object3D;
        this.scene.add(this.goal);
        

        this.iterate(this.character.scene.children);
        this.character.scene.position.set(Global.startPos.x, Global.startPos.y, Global.startPos.z);
        this.character.scene.rotation.set(this.rotationVec.x, this.rotationVec.y, this.rotationVec.z);
        console.log(Global.startPos ,"Global.startPos 2", this.character.scene.position)

        this.mixer = new THREE.AnimationMixer(this.character.scene);
        this.idleAnim = this.mixer.clipAction(this.character.animations[0])
        this.walkAnim = this.mixer.clipAction(this.character.animations[2])
        this.jumpAnim = this.mixer.clipAction(this.character.animations[1])


        console.log(this.character.animations,' animations')
        // this.idleAnim.play();
    }
}