import * as THREE from 'three'
import Camera from './components/Camera'
import Control from './components/Control'
import EventDispatcher from './common/EventDispatcher'
import GUI from './components/GUI'
import Lights from './components/Lights';
import AssetLoader from './components/AssetLoader';
import GameScene from './components/GameScene';
import './sass/main.scss'
import {
    Global
} from './common/global';
import { Server } from "./common/callServer";
import {
    isMobile,
    isMobileOnly
} from 'mobile-device-detect';
import Stats from 'stats.js'
// import './common/jquery.min'
import './common/jquery'
import Character from './components/Character'
import GameControl from './components/GameControl'
// import Physijs from 'physijs';

// THREE.ColorManagement.enabled = false

// const stats = new Stats()
var degtorad = Math.PI / 180;
export default class Game extends THREE.Scene {
    environmentMap = null;
    sizes = {
        width: !isMobile ? window.innerHeight * .56 : window.innerWidth,
        height: window.innerHeight
    }
    resizeTO= null;

    gameTimer=null;
    constructor() {
        super();
        let isFirefox = navigator.userAgent.indexOf("Firefox") != -1;
        const userAgent = navigator.userAgent.toLowerCase();
        Global.isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        if (isMobile) {
            document.querySelector("#canvas_area").classList.add("mobile")
        }
        window.addEventListener('resize', this.onResize.bind(this))

        window.addEventListener('resize', Global.onResize, true);
        Global.onResize();
    }
    init() {
        Global.gameStarted=false;
        Global.canJump = false;
        this.totalCnt = 0;
        this.fpsCalculated = false;
        this.fps = 0;
        this.lastFps = 0;
        this.deltaTime = 0;
        this.fpsInt = null;
        this.then = 0;
        this.clock = new THREE.Clock();
        this.STEPS_PER_FRAME = 5;
        this.lastNow = -1;
        this.lastDiff = -1;
        this.gameStatus = false;

        Global.isMobile = isMobile;
        if (Global.isMobile) {
            Global.viewMode = "portrait";

        } else {

            Global.viewMode = "landscape";
        }
        Global.serverObj = new Server();
        Global.serverObj.send(
            Global.URL_CREATE,
            this.setUID.bind(this),
            null, {
            device: Global.isMobile ? "mobile" : "web",
            fresh: true,
            saveType: 'create',
            utm_source: this.utm_source2
            // source: source,
        },
            "POST",
            null,
            false
        );
        // stats.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
        // document.body.appendChild(stats.dom)
        this.homeJS()

        this.events = EventDispatcher.getObj();
        Global.events= this.events;
        this.events.addEventListener("LOADER_EVENTS", this.onEvents.bind(this));
        this.events.addEventListener("GAME_EVENTS", this.onGameEvents.bind(this));

        this.canvas = document.querySelector('canvas.webgl');

        this.clock = new THREE.Clock()

        this.camera = new Camera(50, this.sizes.width / this.sizes.height, 0.025, 10);
        this.camera.init();
        this.add(this.camera);

        this.gameScene = new GameScene(this, this.camera);
        this.gameScene.init();

        this.character = new Character(this, this.camera);
        this.character.init();

        this.lights = new Lights(this);
        this.lights.init();


        /* this.controls = new Control(this.camera, this.canvas)
        this.controls.init(); */
        this.gameControl = new GameControl(this);
        this.gameControl.init();
        /* 

        this.gameSounds= new Sounds(this.scene);
        this.gameSounds.init(); */

        this.gui = new GUI();
        this.gui.init();

        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            antialias: false,
            precision: 'mediump',
            powerPreference: 'high-performance'
        });
        this.renderer.gammaOutput = true;
        // this.renderer.autoClear=false;
        this.renderer.physicallyCorrectLights = true;
        // this.renderer.outputColorSpace = THREE.LinearSRGBColorSpace
        this.renderer.shadowMap.enabled = false
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
        this.renderer.toneMapping = THREE.LinearToneMapping
        this.renderer.toneMappingExposure = 2.0 * 1.0;
        this.renderer.gammaFactor = 2.2
        this.renderer.useLegacyLights = false;
        this.renderer.setSize(this.sizes.width * 1, this.sizes.height * 1)
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 3));

        /* this.postProcessing=new PostProcessing(this, this.renderer, this.camera);
        this.postProcessing.init(this.sizes); */

        this.assetLoader = new AssetLoader(this);
        this.assetLoader.init();
        this.assetLoader.load();



        this.tick();

    }


    onEvents(data) {
        switch (data['message']['event_type']) {
            case "load_complete":
                this.startGame();
                break;

        }

    }
    replayGame() {
        this.startTimer();
        Global.canJump = false;
    }
    onGameEvents(data) {
        // console.log(data['message']['event_type'],"data['message']['event_type']")
        switch (data['message']['event_type']) {
            case "replay_game":
                this.replayGame();
                break;
            case "stop_timer":
                this.stopTimer();
                break;
            case "pause_game":
                this.stopTimer();
                break;
            case "resume_game":
                Global.gameStarted && this.startTimer(false);
                break;
        }
    }
    startGame() {
        this.environmentMap = Global.assets['cubemap']['0']['asset'];
        this.environmentMap.encoding = THREE.sRGBEncoding;
        this.background = this.environmentMap;
        this.backgroundIntensity = 0.8;

        /* let materialArray=[];
        for(let i =0;i<6;i++){
            let texture= Global.assets['textures'][`bg_${i}`]['asset']
            materialArray.push(new THREE.MeshBasicMaterial({
                map: texture,
                side: THREE.BackSide
              }));
        }
        let geometry = new THREE.BoxGeometry(1, 1, 1);
          let skybox = new THREE.Mesh(geometry, materialArray);
          skybox.scale.set(6,6,6);
          skybox.rotation.x = - 23.44 * Math.PI / 180;
          this.add(skybox); */
        /*  for (let index = 0; index < 6; index++) {
             let texture = new THREE.TextureLoader().load(walls[index]);
             materialArray.push(new THREE.MeshBasicMaterial({
               map: texture,
               side: THREE.BackSide
             }));
           }
           let geometry = new THREE.CubeGeometry(1, 1, 1);
           skybox = new THREE.Mesh(geometry, materialArray);
           skybox.scale.set(skyLength, skyLength, skyLength);
           skybox.rotation.x = - 23.44 * Math.PI / 180;
           scene.add(skybox); */



        this.events.dispatchEvent({
            type: "GAMESCENE_EVENTS",
            message: {
                "event_type": "create_scene",
                data: {

                }
            }
        });

        // this.initGame();
        // document.querySelector("#playBtn").addEventListener("click", () => {
        //     document.querySelector("#playBtn").classList.remove("active");
        //     this.prepareAndStart();

        // });
        // document.querySelector("#playBtn").click();
    }
    setUID(v) {
        Global.U_ID = v["uid"];
        Global.gameKey = v["game_key"];
        setTimeout(
            function () {
                // if (Global.isTablet) {
                //     document.querySelector("#desktopContainer").style.display = "block";
                //     document.querySelector('.loader').style.display = 'none'
                // }
                // if (Global.isMobile) {
                //     document.querySelector(".container").style.display = "block";
                //     document.querySelector(".container").style.left = "0";
                //     document.querySelector('.loader').style.display = 'none'
                //     Global.name = localStorage.getItem("name");
                //     Global.mobile = localStorage.getItem("mobile");
                //     Global.email = localStorage.getItem("email");

                //     // Global.bgAudio.play();
                //     // Global.teamId = location.href.substring(location.href.lastIndexOf('?teamId=') + 1).split("teamId=")[1];
                //     if (
                //         Global.mobile == "" ||
                //         typeof Global.mobile == "undefined" ||
                //         Global.mobile == null ||
                //         Global.mobile == 0 ||
                //         Global.name == "" ||
                //         typeof Global.name == "undefined" ||
                //         Global.name == null ||
                //         Global.name == 0
                //     ) {
                //         document.querySelector("#landingPage").style.display = "flex";
                //     } else {
                //         // Global.teamId = location.href.substring(location.href.lastIndexOf('?teamId=') + 1).split("teamId=")[1];
                //         // document.querySelector('#gullyGame').src = './assets/Top-Tile_' + Global.teamId + '.png';
                //         // document.querySelector('#arExp').src = './assets/' + Global.teamId + '-Left-tile.png';
                //         // document.querySelector('#reward').src = './assets/' + Global.teamId + '-Right-tile.png';
                //         // document.querySelector('#teamLogo').src = './assets/partner-logo-' + Global.teamId + '.png';
                //         // document.querySelector('.welcome').innerHTML = 'Welcome ' + Global.name;
                //         // document.querySelector('.teamSelectPage').style.display = 'none';
                //         Global.U_ID = localStorage.getItem("U_ID");
                //         Global.gameKey = localStorage.getItem("gameKey");
                //         Global.gameTry = localStorage.getItem("gameTry");
                //         Global.image_no = localStorage.getItem("image_no");
                //         console.log(Global.gameKey);
                //         const jwt = localStorage.getItem("jwt");
                //         Global.JWT = jwt;
                //         Global.serverObj.send(
                //             Global.URL_DATA,
                //             (data) => {
                //                 var data = JSON.parse(data);
                //                 if (data.message == "active") {

                //                     Global.loginStatus = true;
                //                     Global.firstName = Global.name.split(" ")[0];
                //                     document.querySelector(
                //                         "#userName"
                //                     ).innerHTML = `hello ${Global.firstName}`;
                //                     document.querySelector("#homePage").style.display = "flex";
                //                     Global.recieptStatusUpdate()
                //                     Global.homePageAnimation(100)

                //                 } else {
                //                     localStorage.clear();
                //                     window.location.href = "https://www.oreoplayfuldunks.com/?utm_source=QR";
                //                 }
                //             },
                //             null,
                //             {
                //                 saveType: "logincheck",
                //                 phone: Global.mobile,
                //                 uid: Global.U_ID,
                //             },
                //             "POST",
                //             null,
                //             false
                //         );
                //     }

                // } else {
                //     document.querySelector("#desktopContainer").style.display = "block";
                //     document.querySelector('.loader').style.display = 'none'

                // }
            }.bind(this),
            1000
        );


    }
    prepareAndStart() {
        Global.gameTry++;
        Global.gameStarted= true;
        Global.clickUpdater('oreo_maze_users', 'gameTry');
        localStorage.setItem('gameTry', Global.gameTry)
        Global.serverObj.send(
            Global.URL_DATA,
            (data) => {
                var data = JSON.parse(data)

                if (data.code == 200) {


                }

                else {
                    localStorage.clear();
                    window.location.href = "https://www.oreoplayfuldunks.com/?utm_source=QR";
                }
            },
            null,
            {
                saveType: "saveData",
                uid: Global.U_ID,
                gameTry: Global.gameTry,
                phone: Global.mobile,
                gender:Global.gender
            },
            "POST",
            null,
            false
        );
        if (Global.playCount == 0) {
            this.permission();
        } else {
            this.events.dispatchEvent({
                type: "GAME_EVENTS",
                message: {
                    "event_type": "replay_game",
                    data: {

                    }
                }
            });
        }
    }

    stopTimer() {
        clearInterval(this.gameTimer);
        this.gameTimer=null;
    }
    
    startTimer(shouldReset= true) {
        if(shouldReset){
            Global.gameTime = 0;
            this.updateTime(false);
        }
        if(this.gameTimer==null)
        this.gameTimer = setInterval(this.updateTime.bind(this), 1000);
        
    }
    updateTime(isUpdate = true) {
        if (Global.gameTime < 60) {
            isUpdate && Global.gameTime++;
            let seconds = Global.gameTime % 60;
            let minutes = Math.floor(Global.gameTime / 60);
            document.querySelector("#game_ui .timer-sec .info").innerHTML = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
        }
        else {
            this.stopTimer();
            Global.gameStarted= false;
            document.querySelector('#loss_Music').play()
            document.querySelector('#GameBG_Music').pause()
            Global.serverObj.send(
                Global.URL_DATA,
                (data) => {
                    var data = JSON.parse(data)

                    if (data.code == 200) {
                        let clearTimerr = setTimeout(() => {
                            clearTimeout(clearTimerr)
                            document.querySelector('#BG_Music').currentTime = 0;
                            document.querySelector('#BG_Music').play()

                            document.querySelector('#scorePage').style.display = 'flex';
                            document.querySelector('.win').style.display = 'none';
                            document.querySelector('.loss').style.display = 'block';
                            Global.canRotate = true;

                            document.querySelector('.container').style.display = 'flex';
                        }, 2000);


                    }

                    else {
                        localStorage.clear();
                        window.location.href = "https://www.oreoplayfuldunks.com/?utm_source=QR";
                    }
                },
                null,
                {
                    saveType: "saveScore",
                    timeTaken: Global.gameTime,
                    uid: Global.U_ID,
                    status: 0,
                    gameTry: Global.gameTry,
                    phone: Global.mobile,
                },
                "POST",
                null,
                false
            );

        }


    }
    initGame() {

        Global.playCount++;
        // alert("initGame")
        setTimeout(() => {
            Global.gameTime = 0;
            this.events.dispatchEvent({
                type: "GAMESCENE_EVENTS",
                message: {
                    "event_type": "start_game",
                    data: {

                    }
                }
            });
            this.startTimer();
        }, 750)
    }
    enableGyro() {
        // alert("S");
        window.addEventListener("deviceorientation", (e) => {
            // do something for 'e' here.

            // let _data= this.getQuaternion(e);
            let _gamma = e.gamma;//_data[2]*Math.sign(e.gamma);//e.gamma ;//+ e.alpha*.01;
            if (Math.abs(_gamma) < 5) {
                this.events.dispatchEvent({
                    type: "GAME_EVENTS",
                    message: {
                        "event_type": "gyro_control_remove",
                        data: {
                            "code": Math.sign(_gamma) == -1 ? "ArrowLeft" : "ArrowRight"
                        }
                    }
                });
            } else {
                // document.querySelector(".info").innerHTML= `${e.gamma}`
                this.events.dispatchEvent({
                    type: "GAME_EVENTS",
                    message: {
                        "event_type": "gyro_control_add",
                        data: {
                            "code": Math.sign(_gamma) == -1 ? "ArrowLeft" : "ArrowRight"
                        }
                    }
                });
            }
            let _beta = e.beta;//_data[1]*Math.sign(e.beta);//e.beta ;//-45;
            if (Math.abs(_beta) < (Math.sign(_beta) == 1 ? 20 : 2) * 1.25 *.5) {
                this.events.dispatchEvent({
                    type: "GAME_EVENTS",
                    message: {
                        "event_type": "gyro_control_remove",
                        data: {
                            "code": Math.sign(_beta) == -1 ? "ArrowUp" : "ArrowDown"
                        }
                    }
                });
            } else {
                this.events.dispatchEvent({
                    type: "GAME_EVENTS",
                    message: {
                        "event_type": "gyro_control_add",
                        data: {
                            "code": Math.sign(_beta) == -1 ? "ArrowUp" : "ArrowDown"
                        }
                    }
                });
            }
            /*  this.events.dispatchEvent({
                 type: "GAMESCENE_EVENTS",
                 message: {
                     "event_type": "start_game",
                     data: {
     
                     }
                 }
             }); */
            // document.querySelector(".game_ui .score_sec .scoreTxt").innerHTML=_gamma ;//+ e.alpha*.01;
        }, false);
    }
    permission1() {
        if (typeof (DeviceMotionEvent) !== "undefined" && typeof (DeviceMotionEvent.requestPermission) === "function") {
            // (optional) Do something before API request prompt.
            DeviceMotionEvent.requestPermission()
                .then(response => {
                    // (optional) Do something after API prompt dismissed.
                    if (response == "granted") {

                        this.enableGyro();
                        this.initGame();
                    } else {
                        document.querySelector("#joystick").classList.add("active2")
                        this.initGame();
                    }
                })
                .catch(() => {
                    this.initGame();
                })
        } else {
            this.initGame();
        }
    }
    permission() {
        var that = this;
        // Function to handle the initialization of the game or features that require device motion.
        function initGameWithGyro() {
            // Assuming your 'enableGyro' function is responsible for starting the gyro functionality.
            // Make sure this function correctly initializes any event listeners or other functionality needed.
            that.enableGyro();

            // Call your game's init function.
            that.initGame();  // Adjust this with the correct method to start your game.
        }

        // Function to handle game initialization without gyro if needed.
        function initGameWithoutGyro() {
            // Initialize the game without gyro support or with alternative controls.
            document.querySelector("#joystick").classList.add("active2");
            that.initGame();  // Adjust this with the correct method to start your game.
        }

        // Main function to request permission and initialize features.
        function requestMotionPermission() {
            // Check if DeviceMotionEvent is available.
            if (typeof DeviceMotionEvent !== "undefined" && typeof DeviceMotionEvent.requestPermission === "function") {
                // For browsers that require permission (iOS 13+).
                DeviceMotionEvent.requestPermission()
                    .then(permissionState => {
                        if (permissionState === "granted") {
                            initGameWithGyro();
                        } else {
                            initGameWithoutGyro();
                        }
                    })
                    .catch(console.error);  // Log the error.
            } else {
                /* if(!Global.isMobile){
                    initGameWithoutGyro();

                }else{ */
                // For browsers that do not require permission (most Android browsers), or if DeviceMotionEvent is not available.
                // You may want to implement additional checks if the device supports motion events.
                if ('ondevicemotion' in window) {
                    // Device supports DeviceMotionEvent, initialize the gyro.
                    initGameWithGyro();
                } else {
                    // Device does not support DeviceMotionEvent.
                    console.warn("DeviceMotionEvent is not supported");
                    initGameWithoutGyro();
                }
                /* } */

            }
        }

        // Start the process.
        requestMotionPermission();
    }
    onResize() {
        this.resizeTO && clearTimeout(this.resizeTO);
        this.resizeTO= setTimeout(() => {
            this.sizes.width = !isMobile?window.innerHeight*.56:window.innerWidth;
            this.sizes.height = window.innerHeight
            this.events.dispatchEvent({
                type: "CAMERA_EVENTS",
                message: {
                    "event_type": "resize",
                    data: {
                        "aspect": this.sizes.width / this.sizes.height,
    
                    }
                }
            });
            this.renderer.setSize(this.sizes.width, this.sizes.height)
            this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 3))
        }, 250);
        // Update sizes
        
    }
    update(now) {
        // stats.begin();




        this.renderer.render(this, this.camera);
        this.events.dispatchEvent({
            type: "GAMESCENE_EVENTS",
            message: {
                "event_type": "update"
            }
        });

        // stats.end();
    }
    tick(now) {
        let delta = this.clock.getDelta();

        let ticks = Math.round(delta / (1 / 120));

        ticks = Math.min(ticks, 20);

        for (let i = 0; i < ticks; i++) {

            this.update(now, delta / ticks);

        };
        window.requestAnimationFrame(this.tick.bind(this))

    }
    onFullScreen(){
        var myScreenOrientation = window.screen.orientation;
        myScreenOrientation.lock("portrait");
    }
    homeJS() {
        this.checkbox1 = false;
        this.checkbox2 = false;
        this.canClick = true;
        this.gender = 'male';
        this.loginSelected = 'phone'
        this.vbool = true;

        // document.querySelector('#signUp').addEventListener('click', () => {
        //     document.querySelector('.container').style.display='none';
        //     this.prepareAndStart();

        // })
        // document.querySelector("#BG_Music").play();
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === 'visible') {
                if (Global.volumeBool) {
                    if(Global.canRotate){
                        document.querySelector("#BG_Music").play();
                    }else{
                        document.querySelector('#GameBG_Music').play()
                    }
                }
            } else {
                document.querySelector("#BG_Music").pause();
                document.querySelector('#GameBG_Music').pause()
            }
        });
        document.querySelector('body').addEventListener('click', () => {
            if (this.vbool) {
                this.vbool = false;
                Global.volumeBool = true;
                document.querySelector("#BG_Music").play();
                document.querySelector('#volumeImg').src = './assets/Volume-On.png'

            }
        })
        document.querySelector('#volume').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }
            if (Global.volumeBool) {
                Global.volumeBool = false;
                document.querySelector("#BG_Music").pause();
                document.querySelector('#volumeImg').src = './assets/Volume-Off.png'
            }
            else {
                Global.volumeBool = true;
                document.querySelector("#BG_Music").currentTime = 0;
                document.querySelector("#BG_Music").play();
                document.querySelector('#volumeImg').src = './assets/Volume-On.png'
            }
        })
        document.querySelector('#checkboxOne').addEventListener('click', () => {
            if (this.checkbox1) {
                this.checkbox1 = false
                document.querySelector('#checkboxOne').src = './assets/Not-Checked-box.png'
            }
            else {
                this.checkbox1 = true
                document.querySelector('#checkboxOne').src = './assets/Checked-box.png'
            }

        })
        document.querySelector('#checkboxTwo').addEventListener('click', () => {
            if (this.checkbox2) {
                this.checkbox2 = false
                document.querySelector('#checkboxTwo').src = './assets/Not-Checked-box.png'
            }
            else {
                this.checkbox2 = true
                document.querySelector('#checkboxTwo').src = './assets/Checked-box.png'
            }

        })
        document.querySelector('#loginSelect').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }
            Global.clickUpdater('oreo_maze_clicks', 'loginSelect_click');

            Global.loadingScreen2('landingPage','loginPage')
            document.querySelector('#back').style.display = 'block'
            // document.querySelector('#landingPage').style.display = 'none';
            // document.querySelector('#loginPage').style.display = 'flex';

        })
        document.querySelector('#signUpSelect').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }
            Global.clickUpdater('oreo_maze_clicks', 'signupSelect_click');

            Global.loadingScreen2('landingPage','signUpPage')
            let  oreoTime=setTimeout(() => {
                clearTimeout(oreoTime)
                document.querySelector('#oreoWithMilk').style.bottom = '-10%'
            }, 500);

            document.querySelector('#back').style.display = 'block'
            // document.querySelector('#landingPage').style.display = 'none';
            // document.querySelector('#signUpPage').style.display = 'flex';

        })
        document.querySelector('#signUp').addEventListener('click', () => {
            if (this.canClick) {
                if (Global.volumeBool) {
                    Global.buttonClick()
                }

                this.signUpValidation();
                // document.querySelector('#discription').innerHTML="Tu t’es connecté(e) avec succès!";
                // document.querySelector('#titleImage').src="./assets/Bravo.png";
                //   document.querySelector('.boxButton').style.display='none';
                //   document.querySelector('#close').style.display='block';
            }
            // document.querySelector('.container').style.display='none';

        })
        document.querySelector('#close').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }

            Global.popUpOff()
            let tinterval = setTimeout(() => {
                clearTimeout(tinterval);
                Global.loadingScreen2('loginPage','homePage','signUpPage')

                // document.querySelector('#signUpPage').style.display = 'none';
                // document.querySelector('#loginPage').style.display = 'none';
                // document.querySelector('#homePage').style.display = 'flex';
                Global.homePageAnimation()
                Global.recieptStatusUpdate()

            }, 1000);

        })
        document.querySelector('#gamePlay').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }
            Global.clickUpdater('oreo_maze_users', 'gamePlay_click');

            Global.loadingScreen2('homePage','instructionPage')
            document.querySelector('#back').style.display = 'block'

            // document.querySelector('#homePage').style.display = 'none';
            // document.querySelector('#instructionPage').style.display = 'flex';

        })
        document.querySelector('#tapToStart').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }
            Global.clickUpdater('oreo_maze_users', 'tapToStart_click');

            Global.loadingScreen2('instructionPage','genderSelectionPage')

            // document.querySelector('#instructionPage').style.display = 'none';
            // document.querySelector('#genderSelectionPage').style.display = 'flex';

            // document.querySelector('.container').style.display='none';
            // this.prepareAndStart();

        })
        document.querySelector('#male').addEventListener('click', () => {
            Global.gender = 'male'
            if (Global.volumeBool) {
                Global.buttonClick()
            }

            // document.querySelector('#male').src = "./assets/Male-selected.png";
            // document.querySelector('#female').src = "./assets/Female-non-selected.png";
            document.querySelector('#male').style.width = "100%";
            document.querySelector('#female').style.width = "85%";
        })
        document.querySelector('#female').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }

            Global.gender = 'female'
            // document.querySelector('#male').src = "./assets/Male-non-selected.png";
            // document.querySelector('#female').src = "./assets/Female-selected.png";
            document.querySelector('#male').style.width = "85%";
            document.querySelector('#female').style.width = "100%";
        })
        document.querySelector('#uploadClose').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }

            // document.querySelector('#imageName').innerHTML = 'Télécharger';
            Global.popUpOff()
        })
        document.querySelector('#start').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }
            document.querySelector('#GameBG_Music').currentTime = 0;
            document.querySelector('#GameBG_Music').play()
            document.querySelector('#BG_Music').pause()
            Global.clickUpdater('oreo_maze_users', 'start_click');

            // Global.loadingScreen2()
            Global.canRotate = false;


            document.querySelector('#genderSelectionPage').style.display = 'none';
            // setTimeout(() => {
            document.querySelector('.container').style.display = 'none';
            // }, 1000);
            this.events.dispatchEvent({
                type: "PLAYER_EVENTS",
                message: {
                    "event_type": "add_character",
                    data: {

                    }
                }
            });
            if (document.body.requestFullScreen) {
                document.body.requestFullScreen().then(this.onFullScreen.bind(this)).catch(() => {
                });
              } else if (document.body.mozRequestFullScreen) {
                document.body.mozRequestFullScreen().then(this.onFullScreen.bind(this)).catch(() => {
                });
              } else if (document.body.webkitRequestFullscreen) {
                let abc= document.body.webkitRequestFullscreen();
                setTimeout(this.onFullScreen.bind(this), 100)
                /* .then(this.onFullScreen.bind(this)).catch(() => {
                }); */
              }
            this.prepareAndStart();
        })

        document.querySelector('#photoUpload').addEventListener('click', () => {
            Global.loadingScreen2('homePage','prizePage')
            if (Global.volumeBool) {
                Global.buttonClick()
            }
            Global.clickUpdater('oreo_maze_users', 'photoUpload_click');

            document.querySelector('#back').style.display = 'block'
            let  oreoTime=setTimeout(() => {
                clearTimeout(oreoTime)
                document.querySelector('#oreoWithMilk').style.bottom = '-10%'
            }, 500);

            // document.querySelector('#homePage').style.display = 'none';
            // document.querySelector('#prizePage').style.display = 'flex';

        })
        document.querySelector('#countinue').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }
            Global.clickUpdater('oreo_maze_users', 'countinue_click');

            document.querySelector('#messageContainer').style.display = 'none'
            document.querySelector('#uploadContainer').style.display = 'block'
            document.querySelector('.uploadContainerButton').style.display = 'block'

            Global.popupOn()

        })
        document.querySelector('#upload').addEventListener('click', () => {
            if (Global.canUpload) {
                if (Global.volumeBool) {
                    Global.buttonClick()
                }

                if (document.getElementById("fileInput").files.length == 0) {
                    document.querySelector('.uploadError').innerHTML = 'Please upload receipt';
                    document.querySelector(".uploadError").style.opacity = 1;
                } else {
                    let coreValue = document.getElementById("fileInput");
                    var files = coreValue.files;
                    let fileName = coreValue.value;
                    let finalFileName = fileName.replace("C:\\fakepath\\", "")
                    Global.clickUpdater('oreo_maze_users', 'upload_click');

                    // if (files.length > 0) {
                    //     document.querySelector('.uploadLoader').style.opacity = 1;
                    //     document.querySelector('.uploadRecipt').style.opacity = 0.4;
                    //     document.querySelector('.uploadError').style.opacity = 0;
                    //     document.getElementById("fileInput").style.display = 'none';
                    //     Global.canUpload = false;
                    //     let setTimer = setTimeout(() => {
                    //         clearTimeout(setTimer);
                    //         function readFile(file) {

                    //             if (!file) return;

                    //             const FR = new FileReader();

                    //             FR.addEventListener("load", function (evt) {
                    //                 //   document.querySelector("#img").src         = evt.target.result;
                    //                 Global.base64data = evt.target.result;
                    //                 console.log(evt.target.result);
                    //                 console.log(Global.base64data);
                    //                 Global.image_no++;
                    //                 Global.clickUpdater('oreo_maze_users', 'image_no');

                    //                 Global.serverObj.send(
                    //                     Global.URL_DATA,
                    //                     (data) => {
                    //                         var data = JSON.parse(data)
                    //                         console.log(data.code);
                    //                         document.getElementById("fileInput").value = ''
                    //                         // document.querySelector('#imageName').innerHTML = 'Télécharger';

                    //                         if (data.code == 200) {
                    //                             document.querySelector(".popupPageContainer").style.top = "-50%";
                    //                             let overTime = setTimeout(() => {
                    //                                 clearTimeout(overTime)
                    //                                 document.querySelector('#discription').innerHTML = "Ta participation est prise en compte pour le grand tirage au sort!";
                    //                                 document.querySelector('#titleImage').style.display = 'block'
                    //                                 document.querySelector('#titleImage').src = './assets/Bravo.png'
                    //                                 document.querySelector('.boxButton').style.display = 'none';
                    //                                 document.querySelector('#close2').style.display = 'block';
                    //                                 document.querySelector('#messageContainer').style.display = 'flex'
                    //                                 document.querySelector('#uploadContainer').style.display = 'none'
                    //                                 document.querySelector(".popupPageContainer").style.top = "50%";
                    //                                 document.querySelector('.uploadLoader').style.opacity = 0;
                    //                                 document.querySelector('.uploadRecipt').style.opacity = 1;
                    //                                 document.getElementById("fileInput").value = '';
                    //                                 document.getElementById("fileInput").style.display = 'block';
                    //                                 Global.canUpload = true;

                    //                             }, 500);
                    //                         }

                    //                         else {
                    //                             if (data.message != "") {


                    //                                 $(".uploadError").html(
                    //                                     "Something went wrong!. <br>Please try again"
                    //                                 );
                    //                                 document.querySelector(".uploadError").style.opacity = 1;
                    //                             }
                    //                         }
                    //                     },
                    //                     null,
                    //                     {
                    //                         saveType: "uploadImage",
                    //                         base64data: btoa(this.base64data),
                    //                         uid: Global.U_ID,
                    //                         image_no: Global.image_no,
                    //                         phone: Global.mobile
                    //                     },
                    //                     "POST",
                    //                     null,
                    //                     false
                    //                 );
                    //             });

                    //             FR.readAsDataURL(file);

                    //         }
                    //         readFile(files[0])

                    //     }, 1000);
                    // }
                    if (files.length > 0) {
                        document.querySelector('.uploadLoader').style.opacity = 1;
                        document.querySelector('.uploadRecipt').style.opacity = 0.4;
                        document.querySelector('.uploadError').style.opacity = 0;
                        document.getElementById("fileInput").style.display = 'none';
                        Global.canUpload = false;
                        var that = this;
                        Global.image_no++;

                        var formData = new FormData();
                        formData.append('user_id', Global.U_ID)
                        formData.append('name', Global.name);
                        formData.append('email', Global.email);
                        formData.append('mobile', Global.mobile);
                        formData.append('image_no', Global.image_no);

                        formData.append("file", files[0]);
                        formData.append('imagename', finalFileName);
                        var xhttp = new XMLHttpRequest();
                        // Set POST method and ajax file path
                        xhttp.open("POST", Global.URL_upload, true);
                       
                        // call on request changes state
                        setTimeout(() => {
                            xhttp.onreadystatechange = function () {
                                if (this.readyState == 4 && this.status == 200) {

                                    var response = atob(this.responseText);
                                    response = JSON.parse(response);
                                    document.querySelector('.uploadLoader').style.opacity = 0;
                                    document.querySelector('.uploadRecipt').style.opacity = 1;
                                    document.getElementById("fileInput").value = '';
                                    document.getElementById("fileInput").style.display = 'block';
                                    Global.canUpload = true;
                                
                                    if (response['code'] != 200) {
                                        alert('something went wronog please try again!')

                                        Global.image_no--;

                                    }

                                    else {
                                        document.querySelector(".popupPageContainer").style.top = "-50%";
                                        let overTime = setTimeout(() => {
                                            clearTimeout(overTime)
                                            document.querySelector('#discription').innerHTML = "Ta participation est prise en compte pour le grand tirage au sort!";
                                            document.querySelector('#titleImage').style.display = 'block'
                                            document.querySelector('#titleImage').src = './assets/Bravo.png'
                                            document.querySelector('.boxButton').style.display = 'none';
                                            document.querySelector('#close2').style.display = 'block';
                                            document.querySelector('#messageContainer').style.display = 'flex'
                                            document.querySelector('#uploadContainer').style.display = 'none'
                                            document.querySelector(".popupPageContainer").style.top = "50%";
                                            Global.clickUpdater('oreo_maze_users', 'image_no');
                                            localStorage.setItem('image_no',Global.image_no)

                                        }, 500);
                                    }
                                }
                            };
                            // Send request with data
                            xhttp.send(formData);
                            this.fbool = true;
                        }, 1000);

                    } else {
                        $(".error").html("Please select a file");
                    }

                }
            }



        })
        document.querySelector('#close2').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }

            Global.popUpOff()
        })
        document.getElementById('fileInput').onchange = function () {
            // document.querySelector('#imageName').innerHTML = 'this.value.replace("C:\\fakepath\\", "")';
            // document.querySelector('#imageName').innerHTML = 'Téléchargé';
            document.querySelector('.uploadError').innerHTML = 'Receipt selected';
            document.querySelector('.uploadError').style.opacity = 1;

        };
        document.querySelector('#mobileNumberLogin').addEventListener('focus', () => {
            document.querySelector('#emailLogin').classList.add('blurEffct')
            document.querySelector('#mobileNumberLogin').classList.remove('blurEffct')
            document.querySelector('.loginPrifix').classList.remove('blurEffct')

            document.querySelector('#emailLogin').value = ''
            this.loginSelected = 'phone';
        })
        document.querySelector('#emailLogin').addEventListener('focus', () => {
            document.querySelector('#mobileNumberLogin').classList.add('blurEffct')
            document.querySelector('#emailLogin').classList.remove('blurEffct')
            document.querySelector('.loginPrifix').classList.add('blurEffct')
            document.querySelector('#mobileNumberLogin').value = ''
            this.loginSelected = 'email';

        })
        document.querySelector('.messageContainerButton').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }

            Global.popUpOff()
        })
        document.querySelector('#retry').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }                    Global.clickUpdater('oreo_maze_users', 'retry_click');

            document.querySelector('#GameBG_Music').currentTime = 0;
            document.querySelector('#GameBG_Music').play()
            document.querySelector('#BG_Music').pause()
            Global.playCount++;


            Global.canRotate = false;
            document.querySelector('#scorePage').style.display = 'none'
            document.querySelector('.container').style.display = 'none'
            this.prepareAndStart()


        })
        document.querySelector('#gotohome').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }

              Global.clickUpdater('oreo_maze_users', 'gotohome_click');

            Global.playCount++;
            document.querySelector('#back').style.display = 'none'
            // document.querySelector('#scorePage').style.display = 'none'
            // document.querySelector('#homePage').style.display = 'flex'
            Global.loadingScreen2('scorePage','homePage')

            Global.homePageAnimation()
            Global.recieptStatusUpdate()


        })
        document.querySelector('#back').addEventListener('click', () => {
            if (Global.volumeBool) {
                Global.buttonClick()
            }
            Global.clickUpdater('oreo_maze_users', 'back_click');

            if (Global.loginStatus) {
                Global.recieptStatusUpdate()
                // document.querySelector('#homePage').style.display = 'flex';
                Global.homePageAnimation()
                document.querySelector('#back').style.display = 'none'
                let  oreoTime=setTimeout(() => {
                            clearTimeout(oreoTime)
                            document.querySelector('#oreoWithMilk').style.bottom = '0%'
                        }, 500);

                Global.loadingScreen2('','homePage')

            }
            else {
                let  oreoTime=setTimeout(() => {
                            clearTimeout(oreoTime)
                            document.querySelector('#oreoWithMilk').style.bottom = '0%'
                        }, 500);


                document.querySelector('#back').style.display = 'none'

                // document.querySelector('#landingPage').style.display = 'flex';
                Global.loadingScreen2('','landingPage')

            }
        })
        var ccc = 0
        var looper = setInterval(() => {
            ccc += 1;
            if (ccc === 21) ccc = 0;
            document.querySelector("#phoneInst").className = `phone-instruction-${ccc}`
        }, 50);

        // var c1 = 0
        // var looper2 = setInterval(() => {
        //     c1 += 1;
        //     if (c1 === 20) c1 = 0;
        //     document.querySelector("#gamePlay").className = `img-01-000${c1}`
        // }, 50);

        // var c2 = 0
        // var looper3 = setInterval(() => {
        //     c2 += 1;
        //     if (c2 === 20) c2 = 0;
        //     document.querySelector("#photoUpload").className = `img-02-000${c2}`
        // }, 50);

        $('#tc').on('click',
        function () {
     
          Global.clickUpdater("oreo_maze_clicks", "tc_click");
          document.querySelector('#popupIDTH').style.display = 'block';
        });
        document.querySelector('#closepopupIDTH').addEventListener('click', () => {
            document.querySelector('#popupIDTH').style.display = 'none';
          });
        // var stoper = setTimeout(() => {
        //     clearInterval(looper)
        //     clearTimeout(stoper)
        // }, 60000);
        document.querySelector('#login').addEventListener('click', () => {
            var k = 0;
            if (Global.volumeBool) {
                Global.buttonClick()
            }

            if (this.loginSelected == 'email') {
                var value = document.getElementById("emailLogin").value;
                var regex =
                    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                if (value == "" || value == null) {
                    document.querySelector('.loginError').innerHTML = "Please enter email id.";
                    document.querySelector(".loginError").style.opacity = 1;

                    return;
                } else if (!regex.test(value)) {
                    document.querySelector('.loginError').innerHTML = "Please enter a valid email id.";
                    document.querySelector(".loginError").style.opacity = 1;

                    return;
                }
                else {
                    k = 1
                }
            }
            else {
                var value = document.getElementById("mobileNumberLogin").value;
                const mobile_regx = /^(\+\d{1,3}[- ]?)?\d{8,10}$/;

                if (value == "" || value == null) {
                    document.querySelector('.loginError').innerHTML = "Please enter mobile no.";
                    document.querySelector(".loginError").style.opacity = 1;

                    return;
                } else if (!mobile_regx.test(value)) {
                    document.querySelector('.loginError').innerHTML = "Please enter a valid mobile no.";
                    document.querySelector(".loginError").style.opacity = 1;

                    return;
                }
                else {
                    k = 1
                }
            }
            if (k == 1) {
                Global.serverObj.send(
                    Global.URL_USER,
                    (data) => {
                        if (data.code == 200) {
                            Global.gameKey = data.game_key;
                            Global.tkn = data.tkn;
                            Global.mobile = value;
                            Global.U_ID = data.uid;
                            Global.name = data.name;
                            Global.email = data.email;
                            Global.gameTry = data.gameTry;
                            Global.image_no = data.image_no;
                            Global.JWT = Global.tkn;

                            Global.loginStatus = true;
                            localStorage.setItem("jwt", Global.tkn);
                            localStorage.setItem("gameKey", Global.gameKey);
                            localStorage.setItem("U_ID", Global.U_ID);
                            localStorage.setItem("name", Global.name);
                            localStorage.setItem("email", Global.email);
                            localStorage.setItem("mobile", Global.mobile);
                            localStorage.setItem("gameTry", Global.gameTry);
                            localStorage.setItem("image_no", Global.image_no);

                            Global.firstName = Global.name.split(" ")[0];

                            document.querySelector(
                                "#userName"
                            ).innerHTML = `Hello ${Global.firstName}`;
                            Global.loginStatus = true;
                            document.querySelector('#discription').innerHTML = "Bravo! Connexion réussie";
                            document.querySelector('#titleImage').style.display = 'block'
                            document.querySelector('#titleImage').src = './assets/Bravo.png'
                            $('.boxButton').hide()
                            // document.querySelector('#close').style.display = 'block';
                            document.querySelector('.popUpBox').style.paddingBottom = '10%'

                            Global.popupOn()
                            setTimeout(() => {
                                Global.popUpOff()
                                let tinterval = setTimeout(() => {
                                    clearTimeout(tinterval);
                                    // setTimeout(() => {
                                        Global.loadingScreen2('loginPage','homePage','signUpPage')

                                    // }, 500);
                                    document.querySelector('#back').style.display = 'none'

                                    document.querySelector('.popUpBox').style.paddingBottom = '0%'

                                    // document.querySelector('#signUpPage').style.display = 'none';
                                    // document.querySelector('#loginPage').style.display = 'none';
                                    // document.querySelector('#homePage').style.display = 'flex';
                                    Global.homePageAnimation()
                                    Global.recieptStatusUpdate()


                                }, 1000);
                            }, 3000);

                        }
                        else if (data.code == 400) {
                            document.querySelector('#discription').innerHTML = "Tu es déjà inscrit(e) <br>sur la plateforme!";
                            document.querySelector('#titleImage').style.display = 'none'
                            $('.boxButton').hide()
                            document.querySelector('.messageContainerButton').style.display = 'block';
                            Global.popupOn()
                        }
                        else {
                            if (data.message != "") {


                                $(".loginError").html(
                                    "Something went wrong!. <br>Please try again"
                                );
                                document.querySelector(".loginError").style.opacity = 1;
                            }
                        }
                    },
                    null,
                    {
                        saveType: "login",
                        value: value,
                        uid: Global.U_ID,
                        loginType: this.loginSelected
                    },
                    "POST",
                    null,
                    false
                );
                Global.clickUpdater('oreo_maze_users', 'login_click');

            }


        })

    }
    signUpValidation() {
        var name = document.getElementById("name").value;
        var email = document.getElementById("email").value;
        var mobile = document.getElementById("mobileNumber").value;
        
        const name_regx = /^[a-zA-Z ]{2,30}$/;
        const mobile_regx = /^(\+\d{1,3}[- ]?)?\d{8,10}$/;
        var regex =
            /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (name.length <= 0) {
            document.querySelector('.signUpError').innerHTML = "Please enter name.";
            document.querySelector(".signUpError").style.opacity = 1;

            return;
        }

        else if (email == "" || email == null) {
            document.querySelector('.signUpError').innerHTML = "Please enter email id.";
            document.querySelector(".signUpError").style.opacity = 1;

            return;
        } else if (!regex.test(email)) {
            document.querySelector('.signUpError').innerHTML = "Please enter a valid email id.";
            document.querySelector(".signUpError").style.opacity = 1;

            return;
        } else if (mobile == "" || mobile == null) {
            document.querySelector('.signUpError').innerHTML = "Please enter mobile no.";
            document.querySelector(".signUpError").style.opacity = 1;

            return;
        } else if (!mobile_regx.test(mobile)) {
            document.querySelector('.signUpError').innerHTML = "Please enter a valid mobile no.";
            document.querySelector(".signUpError").style.opacity = 1;

            return;
        }
        else if (!this.checkbox1) {
            document.querySelector('.signUpError').innerHTML = "Please agree to Terms and Conditions";
            document.querySelector(".signUpError").style.opacity = 1;
            return;
        }
        else {
            this.canClick = false;
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let tempData = null;
            var ga_cid = "1844306653.1689247851";
            try {
              if (ga !== "undefined" || ga !== null) {
                if (ga.getAll()) {
                  tempData = ga.getAll();
                  ga_cid = tempData[0].get("clientId");
      
                }
              }
      
              // if (tempData !== "undefined" || tempData !== null) {
              //   if (tempData.length > 1) {
              //     if (tempData[0].get("clientId")) {
              //       console.log('hereTTTT');
      
              //       ga_cid = tempData[0].get("clientId");
              //     }
              //   }
              // }
            } catch (error) {
              console.log(error, "erroraaa");
            }
      
            if (typeof ga_cid === "undefined" || ga_cid === null) {
              ga_cid = "1844306653.1689247851";
            }
      
      
      
            /* var ga_cid = 1844306653.1689247851 */ /* document.cookie.match(/_ga=(.+?);/)[1].split('.').slice(-2).join(".") */
            this.utm_term = urlParams.get("utm_term");
            this.utm_medium = urlParams.get("utm_medium");
            this.utm_source = urlParams.get("utm_source");
            window.htk = "";
            window.htk = this.getCookie("hubspotutk");
            Global.serverObj.send(
                Global.URL_USER,
                (data) => {

                    if (data.code == 200) {
                        Global.gameKey = data.game_key;
                        Global.tkn = data.tkn;
                        Global.mobile = mobile;
                        Global.name = name;
                        Global.email = email;
                        Global.gameTry = 0;
                        Global.image_no = 0;
                        Global.loginStatus = true;
                        Global.JWT = Global.tkn;
                        Global.loginStatus = true;

                        localStorage.setItem("jwt", Global.tkn);
                        localStorage.setItem("gameKey", Global.gameKey);
                        localStorage.setItem("U_ID", Global.U_ID);
                        localStorage.setItem("name", Global.name);
                        localStorage.setItem("email", Global.email);
                        localStorage.setItem("mobile", Global.mobile);
                        localStorage.setItem("gameTry", Global.gameTry);
                        localStorage.setItem("image_no", Global.image_no);
                        // localStorage.setItem("countryCode", Global.countryCode);
                        Global.firstName = Global.name.split(" ")[0];

                        document.querySelector(
                            "#userName"
                        ).innerHTML = `Hello ${Global.firstName}`;
                        document.querySelector('#discription').innerHTML = "Tu t’es connecté(e) <br>avec succès!";
                        document.querySelector('#titleImage').style.display = 'block'
                        document.querySelector('#titleImage').src = "./assets/Bravo.png";
                        $('.boxButton').hide()
                        Global.clickUpdater('oreo_maze_users', 'signup_click');

                        // document.querySelector('#close').style.display = 'block';
                        document.querySelector('.popUpBox').style.paddingBottom = '10%'
                        let  oreoTime=setTimeout(() => {
                            clearTimeout(oreoTime)
                            document.querySelector('#oreoWithMilk').style.bottom = '0%'
                        }, 500);


                        Global.popupOn()
                        setTimeout(() => {
                            Global.popUpOff()
                            let tinterval = setTimeout(() => {
                                document.querySelector('#back').style.display = 'none'

                                clearTimeout(tinterval);
                                Global.loadingScreen2('loginPage','homePage','signUpPage')
                                document.querySelector('.popUpBox').style.paddingBottom = '0%'

                                // document.querySelector('#signUpPage').style.display = 'none';
                                // document.querySelector('#loginPage').style.display = 'none';
                                // document.querySelector('#homePage').style.display = 'flex';
                                Global.homePageAnimation()
                                Global.recieptStatusUpdate()


                            }, 1000);

                        }, 3000);




                        // else {

                    } else if (data.code == 400) {
                        this.canClick = true
                        document.querySelector('#discription').innerHTML = "Tu n’es pas inscrit(e) sur la plateforme!";
                        document.querySelector('#titleImage').style.display = 'block'
                        document.querySelector('#titleImage').src = "./assets/Oh-no.png";
                        $('.boxButton').hide()
                        document.querySelector('.messageContainerButton').style.display = 'block';
                        Global.popupOn()
                    } else {
                        this.canClick = true;

                        if (data.message != "") {

                        } else {
                            $(".signUpError").html(
                                "Something went wrong!. <br>Please try again"
                            );
                            document.querySelector(".signUpError").style.opacity = 1;
                        }
                    }
                },
                null,
                {
                    'name': name,
                    'email': email,
                    'phone': mobile,
                    'saveType': "register",
                    'uid': Global.U_ID,
                    'ga_cid': ga_cid,
                    'hubspotutk': window.htk,
                    'checkbox': this.checkbox2,
                    'utm_term': this.utm_term,
                    'utm_medium': this.utm_medium,
                    'utm_source': this.utm_source,

                },
                "POST",
                null,
                false
            );


        }
    }
    getCookie(cookie) {

        return document.cookie.split(';').reduce(function (prev, c) {
          var arr = c.split('=');
          return (arr[0].trim() === cookie) ? arr[1] : prev;
        }, undefined);
      }
    

}

const game = new Game();
game.init();